import React from "react";
import classes from "./navbar.module.css";
import NavItems from "./NavItems";
import MenuIcon from "@material-ui/icons/Menu";

const Navbar = (props) => {
  return (
    <nav className={classes.navbar} onClick={props.closeSideBar}>
      <div className={classes.sideMenu} onClick={props.showSideBar}>
        <MenuIcon />
      </div>
      <div className={classes.logo}>
        <img src={require("../../assets/logooo.png")} alt="Saint" /> {/*Edited by Rajesh*/}
        <p>PALLOTTI INSTITUTE MYSURU</p>
      </div>
      <NavItems />
    </nav>
  );
};

export default Navbar;
