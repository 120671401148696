module.exports = [
  {
    title: "Pallotti Institute",
    thumbNail: "img1.jpg",
    folderName: "institute",
    totalImages: 2,
  },
  {
    title: "Prabodhana Campus",
    thumbNail: "img2.jpg",
    folderName: "demoFolder",
    totalImages: 4,
  },
];
