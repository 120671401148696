import React from "react";
import classes from "./footer.module.css";
import NavItems from "../NavBar/NavItems";
import SocialIcon from "../SocialIcons/SocialIcons";
const Footer = () => {
  return (
    <footer className={classes.footer}>
      <NavItems />
      <SocialIcon />
      <p>Copyright © 2020, Pallotti Institute Mysuru All rights reserved.</p>
    </footer>
  );
};

export default Footer;
