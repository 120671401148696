module.exports = {
  generalCategory: [
    {
      accountNumber: "1",
      callNumber: "1.10.KOSA.1",
      bookName: "Sayings of a Saint",
      author: "Augustine Kolencherry",
    },
    {
      accountNumber: "2",
      callNumber: "1.10.KOSA.2",
      bookName: "Sayings of a Saint",
      author: "Augustine Kolencherry",
    },
    {
      accountNumber: "3",
      callNumber: "1.10.KOSA.3",
      bookName: "Sayings of a Saint",
      author: "Augustine Kolencherry",
    },
    {
      accountNumber: "4",
      callNumber: "1.10.KOSA.4",
      bookName: "Sayings of a Saint",
      author: "Augustine Kolencherry",
    },
    {
      accountNumber: "5",
      callNumber: "1.10.MOSA.5",
      bookName: "Sayings of a Saint",
      author: "Augustine Kolencherry",
    },
    {
      accountNumber: "6",
      callNumber: "1.10.BOPA.6",
      bookName: "Pallottiana",
      author: "Flavian ed.",
    },
    {
      accountNumber: "7",
      callNumber: "1.10.DAHU.7",
      bookName: "Hunger and Thirst for God",
      author: "Fr. Joseph Danko. compil.",
    },
    {
      accountNumber: "8",
      callNumber: "1.10.MOSA.8",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "9",
      callNumber: "1.10.MOSA.9",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "10",
      callNumber: "1.10.MOSA.10",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "11",
      callNumber: "1.10.MOSA.11",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "12",
      callNumber: "1.10.MOSA.12",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "13",
      callNumber: "1.10.MOSA.13",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "14",
      callNumber: "1.10.MOSA.14",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "15",
      callNumber: "1.10.MOSA.15",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "16",
      callNumber: "1.10.MOSA.16",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "17",
      callNumber: "1.10.MOSA.17",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "18",
      callNumber: "1.10.MOSA.18",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "19",
      callNumber: "1.10.MOSA.19",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "20",
      callNumber: "1.10.MOSA.20",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "21",
      callNumber: "1.10.MOSA.21",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "22",
      callNumber: "1.10.MOSA.22",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "23",
      callNumber: "1.10.MOSA.23",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "24",
      callNumber: "1.10.MOSA.24",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "25",
      callNumber: "1.10.MOSA.25",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "26",
      callNumber: "1.10.MOSA.26",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "27",
      callNumber: "1.10.MOSA.27",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "28",
      callNumber: "1.10.MOSA.28",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "29",
      callNumber: "1.10.MOSA.29",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "30",
      callNumber: "1.10.MOSA.30",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "31",
      callNumber: "1.10.MOSA.31",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "32",
      callNumber: "1.10.MOSA.32",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "33",
      callNumber: "1.10.MOSA.33",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "34",
      callNumber: "1.10.MOSA.34",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "35",
      callNumber: "1.10.MOSA.35",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "36",
      callNumber: "1.10.SCSY.36",
      bookName: "Synopse der Schriften Pallottis",
      author: "Ulrich",
    },
    {
      accountNumber: "37",
      callNumber: "1.10.MOSA.37",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "38",
      callNumber: "1.10.MOSA.38",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "39",
      callNumber: "1.10.MOSA.39",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "40",
      callNumber: "1.10.MOSA.40",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "41",
      callNumber: "1.10.MOSA.41",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "42",
      callNumber: "1.10.MOSA.42",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "43",
      callNumber: "1.10.MOSA.43",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "44",
      callNumber: "1.10.MOSA.44",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "45",
      callNumber: "1.10.MOSA.45",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "46",
      callNumber: "1.10.MOSA.46",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "47",
      callNumber: "1.10.MOSA.47",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "48",
      callNumber: "1.10.MOSA.48",
      bookName: "San Vincenzo Pallotti, Opere Complete",
      author: "Francesco ed.",
    },
    {
      accountNumber: "49",
      callNumber: "1.10.BASA.49",
      bookName: "San Vincenzo Pallotti, Opere Complete, Lettere",
      author: "Bruno ed.",
    },
    {
      accountNumber: "50",
      callNumber: "1.10.BASA.50",
      bookName: "San Vincenzo Pallotti, Opere Complete, Lettere",
      author: "Bruno ed.",
    },
    {
      accountNumber: "51",
      callNumber: "1.10.BASA.51",
      bookName: "San Vincenzo Pallotti, Opere Complete, Lettere",
      author: "Bruno ed.",
    },
    {
      accountNumber: "52",
      callNumber: "1.10.BASA.52",
      bookName: "San Vincenzo Pallotti, Opere Complete, Lettere",
      author: "Bruno ed.",
    },
    {
      accountNumber: "53",
      callNumber: "1.10.BASA.53",
      bookName: "San Vincenzo Pallotti, Opere Complete, Lettere",
      author: "Bruno ed.",
    },
    {
      accountNumber: "54",
      callNumber: "1.10.BASA.54",
      bookName: "San Vincenzo Pallotti, Opere Complete, Lettere",
      author: "Bruno ed.",
    },
    {
      accountNumber: "55",
      callNumber: "1.10.BASA.55",
      bookName: "San Vincenzo Pallotti, Opere Complete, Lettere",
      author: "Bruno ed.",
    },
    {
      accountNumber: "56",
      callNumber: "1.10.BASA.56",
      bookName: "San Vincenzo Pallotti, Opere Complete, Lettere",
      author: "Bruno ed.",
    },
    {
      accountNumber: "57",
      callNumber: "1.10.BASA.57",
      bookName: "San Vincenzo Pallotti, Opere Complete, Lettere",
      author: "Bruno ed.",
    },
    {
      accountNumber: "58",
      callNumber: "1.10.BASA.58",
      bookName: "San Vincenzo Pallotti, Opere Complete, Lettere",
      author: "Bruno ed.",
    },
    {
      accountNumber: "59",
      callNumber: "1.10.BASA.59",
      bookName: "San Vincenzo Pallotti, Opere Complete, Lettere",
      author: "Bruno ed.",
    },
    {
      accountNumber: "60",
      callNumber: "1.10.BASA.60",
      bookName: "Lettere - Anni 1849-1850 - San vincenzo Pallotti",
      author: "Bruno",
    },
    {
      accountNumber: "61",
      callNumber: "1.10.BASA.61",
      bookName: "San Vincenzo Pallotti, Opere Complete, Lettere",
      author: "Bruno ed.",
    },
    {
      accountNumber: "62",
      callNumber: "1.10.BASA.62",
      bookName: "San Vincenzo Pallotti, Opere Complete, Lettere",
      author: "Bruno ed.",
    },
    {
      accountNumber: "63",
      callNumber: "1.10.GOLI.63",
      bookName: "Live Your Consecration",
      author: "Goldschmidt A. H.",
    },
    {
      accountNumber: "64",
      callNumber: "1.10.GOLI.64",
      bookName: "Live Your Consecration",
      author: "Goldschmidt A. H.",
    },
    {
      accountNumber: "65",
      callNumber: "1.10.GOLI.65",
      bookName: "Live Your Consecration",
      author: "Goldschmidt A. H.",
    },
    {
      accountNumber: "66",
      callNumber: "1.10.GOLI.66",
      bookName: "Live Your Consecration",
      author: "Goldschmidt A. H.",
    },
    {
      accountNumber: "67",
      callNumber: "1.10.GOLI.67",
      bookName: "Live Your Consecration",
      author: "Goldschmidt A. H.",
    },
    {
      accountNumber: "68",
      callNumber: "1.10.GOLI.68",
      bookName: "Live Your Consecration",
      author: "Goldschmidt A. H.",
    },
    {
      accountNumber: "69",
      callNumber: "1.10.GOLI.69",
      bookName: "Live Your Consecration",
      author: "Goldschmidt A. H.",
    },
    {
      accountNumber: "70",
      callNumber: "1.10.GOLI.70",
      bookName: "Live Your Consecration",
      author: "Goldschmidt A. H.",
    },
    {
      accountNumber: "71",
      callNumber: "1.10.KOSA.71",
      bookName: "Sayings of a Saint",
      author: "Augustine Kolencherry",
    },
    {
      accountNumber: "72",
      callNumber: "1.10.DAHU.72",
      bookName: "Hunger and Thirst for God",
      author: "Joseph ed.",
    },
    {
      accountNumber: "73",
      callNumber: "1.10.DAHU.73",
      bookName: "Hunger and Thirst for God",
      author: "Joseph ed.",
    },
    {
      accountNumber: "74",
      callNumber: "1.10.DAHU.74",
      bookName: "Hunger and Thirst for God",
      author: "Joseph ed.",
    },
    {
      accountNumber: "75",
      callNumber: "1.10.DAHU.75",
      bookName: "Hunger and Thirst for God",
      author: "Joseph ed.",
    },
    {
      accountNumber: "76",
      callNumber: "1.10.DAHU.76",
      bookName: "Hunger and Thirst for God",
      author: "Joseph ed.",
    },
    {
      accountNumber: "77",
      callNumber: "1.10.DAHU.77",
      bookName: "Hunger and Thirst for God",
      author: "Joseph ed.",
    },
    {
      accountNumber: "78",
      callNumber: "1.10.DAHU.78",
      bookName: "Hunger and Thirst for God",
      author: "Joseph ed.",
    },
    {
      accountNumber: "79",
      callNumber: "1.10.DAHU.79",
      bookName: "Hunger and Thirst for God",
      author: "Joseph ed.",
    },
    {
      accountNumber: "80",
      callNumber: "1.10.DAHU.80",
      bookName: "Hunger and Thirst for God",
      author: "Joseph ed.",
    },
    {
      accountNumber: "81",
      callNumber: "1.10.BOST.81",
      bookName: "St. Vincent Pallotti, Pathways to God",
      author: "Flavian",
    },
    {
      accountNumber: "82",
      callNumber: "1.10.BOST.82",
      bookName: "St. Vincent Pallotti, Pathways to God",
      author: "Flavian",
    },
    {
      accountNumber: "83",
      callNumber: "1.10.LUSA.83",
      bookName: "Saint Vincent Pallotti, Complete Writings",
      author: "Louis J. ed.",
    },
    {
      accountNumber: "84",
      callNumber: "1.10.LUSA.84",
      bookName: "Saint Vincent Pallotti, Complete Writings VOL. 1  -  Piou",
      author: "Louis J. ed.",
    },
    {
      accountNumber: "85",
      callNumber: "1.10.TITO.85",
      bookName: "To Troubled Hearts",
      author: "George (translator)",
    },
    {
      accountNumber: "86",
      callNumber: "1.10.TITO.86",
      bookName: "To Troubled Hearts",
      author: "George (translator)",
    },
    {
      accountNumber: "87",
      callNumber: "1.10.GRSP.87",
      bookName: "Spiritual Diary",
      author: "Domenick T. Trans.",
    },
    {
      accountNumber: "88",
      callNumber: "1.10.GRSP.88",
      bookName: "Spiritual Diary",
      author: "Domenick T. Trans.",
    },
    {
      accountNumber: "89",
      callNumber: "1.10.GRSP.89",
      bookName: "Spiritual Diary",
      author: "Domenick T. Trans.",
    },
    {
      accountNumber: "90",
      callNumber: "1.10.GRSP.90",
      bookName: "Spiritual Diary",
      author: "Domenick T. Trans.",
    },
    {
      accountNumber: "91",
      callNumber: "1.10.BOPA.91",
      bookName: "Pallottiana",
      author: "Flavian ed.",
    },
    {
      accountNumber: "92",
      callNumber: "1.10.KOSA.92",
      bookName: "Sayings of a Saint",
      author: "Augustine Kolencherry",
    },
    {
      accountNumber: "93",
      callNumber: "1.10.PALE.93",
      bookName: "Lettera Ai Confratelli Londinesi",
      author: "Vincenzo",
    },
    {
      accountNumber: "94",
      callNumber: "1.10.KOSA.94",
      bookName: "Sayings of A Saint",
      author: "Augustine Kolencherry",
    },
    {
      accountNumber: "95",
      callNumber: "1.10.FASA.95",
      bookName: "San Vincenzo Pallotti",
      author: "Ansgario.  ed.",
    },
    {
      accountNumber: "96",
      callNumber: "1.10.NOLE.96",
      bookName: "Le Direttive Di San Vincenzo Pallotti",
      author: "Battistina M.",
    },
    {
      accountNumber: "97",
      callNumber: "1.10.SALU.97",
      bookName: "Luci Del Mattino",
      author: "Gennarino  ed.",
    },
    {
      accountNumber: "98",
      callNumber: "1.10.PAMA.98",
      bookName: "Maria Ci Parla",
      author: "Vincenzo",
    },
    {
      accountNumber: "99",
      callNumber: "1.10.SALU.99",
      bookName: "Luci Del Mattino",
      author: "Gennarino  ed.",
    },
    {
      accountNumber: "100",
      callNumber: "1.10.SAFI.100",
      bookName: "Fiori D`Estate",
      author: "Gennarino  ed.",
    },
    {
      accountNumber: "101",
      callNumber: "1.10.SALU.101",
      bookName: "Luci Del Tramonto",
      author: "Gennarino  ed.",
    },
    {
      accountNumber: "102",
      callNumber: "1.10.SACR.102",
      bookName: "Crediamo All`Amore",
      author: "Gennarino  ed.",
    },
    {
      accountNumber: "103",
      callNumber: "1.10.SACO.103",
      bookName: "Con Maria Nel Cenacolo",
      author: "Gennarino  ed.",
    },
    {
      accountNumber: "104",
      callNumber: "1.10.SAPR.104",
      bookName: "Presto Santi Grandi Santi",
      author: "Gennarino  ed.",
    },
    {
      accountNumber: "105",
      callNumber: "1.10.SAGI.105",
      bookName: "Gioia Di Vivere",
      author: "Gennarino  ed.",
    },
    {
      accountNumber: "106",
      callNumber: "1.10.SAGI.106",
      bookName: "Gioia De Amare",
      author: "Gennarino  ed.",
    },
    {
      accountNumber: "107",
      callNumber: "1.10.SACA.107",
      bookName: "Canto D` Amore",
      author: "Gennarino  ed.",
    },
    {
      accountNumber: "108",
      callNumber: "1.10.SACA.108",
      bookName: "Canto D`Amore",
      author: "Gennarino  ed.",
    },
    {
      accountNumber: "109",
      callNumber: "1.10.SACA.109",
      bookName: "Canto di Misericordia",
      author: "Gennarino  ed.",
    },
    {
      accountNumber: "110",
      callNumber: "1.10.SACO.110",
      bookName: "Concilio e Riforma",
      author: "Gennarino  ed.",
    },
    {
      accountNumber: "111",
      callNumber: "1.10.SADI.111",
      bookName: "Dio I`Impazzito de Amore",
      author: "Gennarino  ed.",
    },
    {
      accountNumber: "112",
      callNumber: "1.10.SADI.112",
      bookName: "Dio I`Impazzito di Misericordia",
      author: "Gennarino  ed.",
    },
    {
      accountNumber: "113",
      callNumber: "1.10.SAMA.113",
      bookName: "Maria Ci Parla",
      author: "Gennarino  ed.",
    },
    {
      accountNumber: "114",
      callNumber: "1.10.SAFI.114",
      bookName: "Fior Da Fiore Lettere",
      author: "Gennarino  ed.",
    },
    {
      accountNumber: "115",
      callNumber: "1.10.SACR.115",
      bookName: "Crediamo all`Amore",
      author: "Gennarino",
    },
    {
      accountNumber: "116",
      callNumber: "1.10.PARE.116",
      bookName: "Regole Fondamentali Della Societa Dell`Apostolato Catto",
      author: "Vincenzo",
    },
    {
      accountNumber: "117",
      callNumber: "1.10.PADD.117",
      bookName: "Ddio L`Amore Infinito",
      author: "Vincenzo",
    },
    {
      accountNumber: "118",
      callNumber: "1.10.PARE.118",
      bookName: "Regole Fondamentali Della Societa Dell`Apostolato Catto",
      author: "Vincenzo",
    },
    {
      accountNumber: "119",
      callNumber: "1.10.ERIN.119",
      bookName: "Informacoes Palotinas",
      author: "Edgar Xavier.  ed.",
    },
    {
      accountNumber: "120",
      callNumber: "1.10.ERIN.120",
      bookName: "Informacoes Palotinas",
      author: "Edgar Xavier.  ed.",
    },
    {
      accountNumber: "121",
      callNumber: "1.10.HOMA.121",
      bookName: "Maria Regina Degli Apostoli per il Cristiano di oggi",
      author: "Stella-Otylia",
    },
    {
      accountNumber: "122",
      callNumber: "1.10.AMLA.122",
      bookName: "La Via. dell`infinito",
      author: "Francesco",
    },
    {
      accountNumber: "123",
      callNumber: "1.10.BAVI.123",
      bookName: "Vinzenz Pallotti",
      author: "Bruno ed.",
    },
    {
      accountNumber: "124",
      callNumber: "1.10.GESP.124",
      bookName: "Spiritual Diary",
      author: "Domenick T. Trans.",
    },
    {
      accountNumber: "125",
      callNumber: "2.10.LISO.1",
      bookName: "Society of the Catholic Apostolate The",
      author: "Harold (Publisher)",
    },
    {
      accountNumber: "126",
      callNumber: "2.10.LISO.2",
      bookName: "Society of the Catholic Apostolate The",
      author: "Harold (Publisher)",
    },
    {
      accountNumber: "127",
      callNumber: "2.10.FAID.3",
      bookName: "Idea of Vincent Pallotti Concerning The Society of the Ca",
      author: "Ansgar",
    },
    {
      accountNumber: "128",
      callNumber: "2.10.FAID.4",
      bookName: "Idea of Vincent Pallotti Concerning The Society of the Ca",
      author: "Ansgar",
    },
    {
      accountNumber: "129",
      callNumber: "2.10.ANIN.5",
      bookName: "Introduction to the Society of the Catholic Apostolate",
      author: "Anonymous",
    },
    {
      accountNumber: "130",
      callNumber: "2.10.SOHI.6",
      bookName: "Historia  (1935 - 1950)",
      author: "Society of the Catholic Apostola",
    },
    {
      accountNumber: "131",
      callNumber: "2.10.SOHI.7",
      bookName: "Historia  (1835 - 1935)",
      author: "Society of the Catholic Apostola",
    },
    {
      accountNumber: "132",
      callNumber: "2.10.SOHI.8",
      bookName: "Historia (1935 - 1950)",
      author: "Society of the Catholic Apostola",
    },
    {
      accountNumber: "133",
      callNumber: "2.10.BOOU.9",
      bookName: "Our pallottine Heritage",
      author: "Flavian",
    },
    {
      accountNumber: "134",
      callNumber: "2.10.BOOU.10",
      bookName: "Our Pallottine Heritage",
      author: "Flavian",
    },
    {
      accountNumber: "135",
      callNumber: "2.10.BOOU.11",
      bookName: "Our Pallottine Heritage",
      author: "Flavian",
    },
    {
      accountNumber: "136",
      callNumber: "2.10.BOOU.12",
      bookName: "Our Pallottine Heritage",
      author: "Flavian",
    },
    {
      accountNumber: "137",
      callNumber: "2.10.UNUN.13",
      bookName: "Un Nuovo Inizio Per Tempi Nuovi",
      author: "Unione Dell`Apostolato Cattolic",
    },
    {
      accountNumber: "138",
      callNumber: "2.10.SCDE.14",
      bookName: "Der Beginn",
      author: "Heinrich",
    },
    {
      accountNumber: "139",
      callNumber: "2.10.DWIN.15",
      bookName: "Introducing The Pallottines",
      author: "Fr. Patrick Dwyer",
    },
    {
      accountNumber: "140",
      callNumber: "2.11.ANLI.1",
      bookName: "Like the Mustard Seed",
      author: "James",
    },
    {
      accountNumber: "141",
      callNumber: "2.11.ANLI.2",
      bookName: "Like the Mustard Seed",
      author: "James",
    },
    {
      accountNumber: "142",
      callNumber: "2.11.ANLI.3",
      bookName: "Like the Mustard Seed",
      author: "James",
    },
    {
      accountNumber: "143",
      callNumber: "2.11.GAEN.4",
      bookName: "English-Speaking Pallottines The",
      author: "John S.",
    },
    {
      accountNumber: "144",
      callNumber: "2.11.AVLI.5",
      bookName: "Like an Evangelical Trumpet",
      author: "Steven M.",
    },
    {
      accountNumber: "145",
      callNumber: "2.11.AVLI.6",
      bookName: "Like an Evangelical Trumpet",
      author: "Steven M.",
    },
    {
      accountNumber: "146",
      callNumber: "2.11.PRSO.7",
      bookName: "Society of the Catholic Apostolate - Prabu Prakash Provin",
      author: "Prabhu Prakash Province",
    },
    {
      accountNumber: "147",
      callNumber: "2.11.PRSO.8",
      bookName: "Society of the Catholic Apostolate - Prabu Prakash Provin",
      author: "Prabhu Prakash Province",
    },
    {
      accountNumber: "148",
      callNumber: "2.11.PRSO.9",
      bookName: "Society of the Catholic Apostolate - Prabu Prakash Provin",
      author: "Prabhu Prakash Province",
    },
    {
      accountNumber: "149",
      callNumber: "2.11.PRSO.10",
      bookName: "Society of the Catholic Apostolate -  Prabu Prakash Provi",
      author: "Prabhu Prakash Province",
    },
    {
      accountNumber: "150",
      callNumber: "2.11.GAEN.11",
      bookName: "English-Speaking Pallottines The",
      author: "John S.",
    },
    {
      accountNumber: "151",
      callNumber: "2.11.GAEN.12",
      bookName: "English-Speaking Pallottines The",
      author: "John S.",
    },
    {
      accountNumber: "152",
      callNumber: "2.11.XNIN.13",
      bookName: "Informativo Da Provincia Nossa Senhora Conquistadora-",
      author: "Edgar ed",
    },
    {
      accountNumber: "153",
      callNumber: "2.11.SURU.14",
      bookName: "Rundbrief",
      author: "Sueddeutsche Provinz Der Pallot",
    },
    {
      accountNumber: "154",
      callNumber: "2.11..SURU.15",
      bookName: "Rundbrief",
      author: "Sueddeutsche Provinz Der Pallot",
    },
    {
      accountNumber: "155",
      callNumber: "2.11.SURU.16",
      bookName: "Rundbrief",
      author: "Sueddeutsche Provinz Der Pallot",
    },
    {
      accountNumber: "156",
      callNumber: "2.11.SKP.17",
      bookName: "P. S. M.",
      author: "Hermann",
    },
    {
      accountNumber: "157",
      callNumber: "2.11.PRNA.18",
      bookName: "Namenstage Unserer Mitbruder(Herz-Jesu-Provinz)",
      author: "Provinzialat Der Pallottiner",
    },
    {
      accountNumber: "158",
      callNumber: "2.11.DESO.19",
      bookName: "Sociedad Del Apostolado Catolico PP. Palotinos 1952-19",
      author: "Angel F.",
    },
    {
      accountNumber: "159",
      callNumber: "2.11.STJU.20",
      bookName: "Jubileusz Seminarium Duchownego 1918 - 1968",
      author: "Stowarzyszenie Apostolstwa Kat",
    },
    {
      accountNumber: "160",
      callNumber: "2.11.SUSU.21",
      bookName: "Sueddeutsche Pallottinerprovinz",
      author: "Sueddeutsche Pallottinerprovinz",
    },
    {
      accountNumber: "161",
      callNumber: "2.11.GNTH.22",
      bookName: "Thamizhaha Pallottiarin Varalaru",
      author: "Gnanaprakasam ed.",
    },
    {
      accountNumber: "162",
      callNumber: "2.11.THTH.23",
      bookName: "Thooya Vincent Pallotti Aalayam, Thirunagar, Madurai",
      author: "Thooya Vincent Pallotti Aalaya",
    },
    {
      accountNumber: "163",
      callNumber: "2.11.SUSU.24",
      bookName: "Sueddeutsche Pallottinerprovinz",
      author: "Sueddeutsche Pallottinerprovinz",
    },
    {
      accountNumber: "164",
      callNumber: "2.11.LEEI.25",
      bookName: "Eine Geistliche Unternehmensgeschichte",
      author: "Antonia",
    },
    {
      accountNumber: "165",
      callNumber: "2.11.KUPU.26",
      bookName: "Punitha Augusthinar Kulaseharam Pankin Varalattu Pathi",
      author: "Kulaseharam Parish",
    },
    {
      accountNumber: "166",
      callNumber: "2.11.PAPA.27",
      bookName: "Pallottines - Society of the Catholic Apostolate",
      author: "Pallotti Press Queenstown",
    },
    {
      accountNumber: "167",
      callNumber: "2.11.BEST.28",
      bookName: "St. Peter`s Italian Church",
      author: "Peter (compiler)",
    },
    {
      accountNumber: "168",
      callNumber: "2.11.BEST.29",
      bookName: "St. Peter`s Italian Church",
      author: "Peter (compiler)",
    },
    {
      accountNumber: "169",
      callNumber: "2.11.ASAS.30",
      bookName: "Assumption of the B.V.M. Province - Acta III - 2010",
      author: "Assumption of the B.V.M. Provi",
    },
    {
      accountNumber: "170",
      callNumber: "2.11.ASAS.31",
      bookName: "Assumption of the B.V.M. Province - Acta III - 2010",
      author: "Assumption of the B.V.M. Provi",
    },
    {
      accountNumber: "171",
      callNumber: "2.11.ASAS.32",
      bookName: "Assumption of the B.V.M. Province - Acta III - 2010",
      author: "Assumption of the B.V.M. Provi",
    },
    {
      accountNumber: "172",
      callNumber: "2.11.GAPA.33",
      bookName: "Pallottine Congress 2001",
      author: "Vensus A. ed.",
    },
    {
      accountNumber: "173",
      callNumber: "2.11.IRNE.34",
      bookName: "Necrology of Priests and Brothers of the Irish Province",
      author: "Irish Province of the Pallottines",
    },
    {
      accountNumber: "174",
      callNumber: "2.11.SUCE.35",
      bookName: "Cento Cinquantissimo Anniversario Apertura Della Pia C",
      author: "Suore dell`Apostolato Cattolico",
    },
    {
      accountNumber: "175",
      callNumber: "2.11.SAEN.36",
      bookName: "Encontro Dos Formadores Palotinos DA America Do Sul",
      author: "Santa Maria/RS, Brasil",
    },
    {
      accountNumber: "176",
      callNumber: "2.11.ERCO.37",
      bookName: "Colegio Maximo Palotino",
      author: "Edgar Xavier.  ed.",
    },
    {
      accountNumber: "177",
      callNumber: "2.11.NANO.38",
      bookName: "Nothing is Wasted in the Household of God",
      author: "Brigida",
    },
    {
      accountNumber: "178",
      callNumber: "2.11.NANO.39",
      bookName: "Nothing is Wasted in the Household of God",
      author: "Brigida",
    },
    {
      accountNumber: "179",
      callNumber: "2.11.NANO.40",
      bookName: "Nothing is Wasted in the Household of God",
      author: "Brigida",
    },
    {
      accountNumber: "180",
      callNumber: "2.11.NANO.41",
      bookName: "Nothing is Wasted in the Household of God",
      author: "Brigida",
    },
    {
      accountNumber: "181",
      callNumber: "2.11.PR15.42",
      bookName: "150 Anni Di Presenza Dei Pallottini A Rocca Priora 1858",
      author: "Mario",
    },
    {
      accountNumber: "182",
      callNumber: "2.11.PATA.43",
      bookName: "Tardun Chronicle The 1926 - 1964",
      author: "Pallottines at Tardun W. A.",
    },
    {
      accountNumber: "183",
      callNumber: "2.11.PATA.44",
      bookName: "Tardun Chronicle The 1926 - 1964",
      author: "Pallottines at Tardun W. A.",
    },
    {
      accountNumber: "184",
      callNumber: "2.11.GAEN.45",
      bookName: "English-Speaking Pallottines the",
      author: "John S. Gaynor",
    },
    {
      accountNumber: "185",
      callNumber: "2.11.CELO.46",
      bookName: "Love of Christ urges us on The",
      author: "C R I Permanent Secretariate",
    },
    {
      accountNumber: "186",
      callNumber: "2.11.CELO.47",
      bookName: "Love of Christ Urges Us On The",
      author: "Central Thread The",
    },
    {
      accountNumber: "187",
      callNumber: "2.11.CELO.48",
      bookName: "Love of Chrust Urges Us On The",
      author: "Central Thread The",
    },
    {
      accountNumber: "188",
      callNumber: "2.11.CELO.49",
      bookName: "Love of Christ Urges Us On The",
      author: "Central Thread The",
    },
    {
      accountNumber: "189",
      callNumber: "2.11.AVLI.50",
      bookName: "Like An Evangelical Trlimpet",
      author: "Steven M.",
    },
    {
      accountNumber: "190",
      callNumber: "2.11.ASAS.51",
      bookName: "Assumption of the B.V.M. Province - Acta IV - 2013",
      author: "Assumption of the B.V.M. Provi",
    },
    {
      accountNumber: "191",
      callNumber: "2.11.ASAS.52",
      bookName: "Assumption of the B.V.M. Province - Acta  IV - 2013",
      author: "Assumption of the B.V.M. Provi",
    },
    {
      accountNumber: "192",
      callNumber: "2.11.ASSO.53",
      bookName: "Assumption of the B.V.M. Province - Acta  II - 2007",
      author: "Assumption of the B.V.M. Provi",
    },
    {
      accountNumber: "193",
      callNumber: "3.10.SOCO.1",
      bookName: "Commentario Giuridico Alla Legge Della Societa dell Ap",
      author: "Hubert",
    },
    {
      accountNumber: "194",
      callNumber: "3.10.SOLA.2",
      bookName: "Law of the Society of the Catholic Apostolate",
      author: "Society of the Catholic Apostola",
    },
    {
      accountNumber: "195",
      callNumber: "3.10.SOLA.3",
      bookName: "Law of the Society of the CAtholic Apostolate",
      author: "Society of the Catholic Apostola",
    },
    {
      accountNumber: "196",
      callNumber: "3.10.SOCO.4",
      bookName: "Commentario Giuridico",
      author: "Hubert",
    },
    {
      accountNumber: "197",
      callNumber: "3.10.SOCO.5",
      bookName: "Constitutiones Societatis Apostolatus Catholici",
      author: "Society of the Catholic Apostola",
    },
    {
      accountNumber: "198",
      callNumber: "3.10.GEGR.6",
      bookName: "Grundgesetz",
      author: "Gesellschaft des Katholischen A",
    },
    {
      accountNumber: "199",
      callNumber: "3.10.LUNA.7",
      bookName: "Natura Giuridica Della Societa Dell`Apostolato Cattolico",
      author: "Janusz",
    },
    {
      accountNumber: "200",
      callNumber: "3.10.SOLA.8",
      bookName: "Law of the Society of the Catholic Apostolate",
      author: "Society of the Catholic Apostola",
    },
    {
      accountNumber: "201",
      callNumber: "3.10.SOLE.9",
      bookName: "Legge della Societa dell`Apostolato Cattolico",
      author: "Societa dell Apostolato Cattolico",
    },
    {
      accountNumber: "202",
      callNumber: "3.10.SOLA.10",
      bookName: "Law of the society of the Catholic Apostolate",
      author: "Tipolitografia",
    },
    {
      accountNumber: "203",
      callNumber: "3.10.SOLA.11",
      bookName: "Law of the society of the Catholic Apostolate",
      author: "Law of the society of the Catholi",
    },
    {
      accountNumber: "204",
      callNumber: "3.11.MUOU.1",
      bookName: "Our Apostolate Today",
      author: "Ludwig",
    },
    {
      accountNumber: "205",
      callNumber: "3.11.MUOU.2",
      bookName: "Our Poverty",
      author: "Ludwig",
    },
    {
      accountNumber: "206",
      callNumber: "3.11.MUOU.3",
      bookName: "Our Poverty",
      author: "Ludwig",
    },
    {
      accountNumber: "207",
      callNumber: "3.11.MUBR.4",
      bookName: "Brothers in Our Society The",
      author: "Ludwig",
    },
    {
      accountNumber: "208",
      callNumber: "3.11.JUEL.5",
      bookName: "Elections in Our Society",
      author: "Martin",
    },
    {
      accountNumber: "209",
      callNumber: "3.11.FRME.6",
      bookName: "Memory and Prophecy of the Union of Catholic Apostolat",
      author: "Seamus",
    },
    {
      accountNumber: "210",
      callNumber: "3.11.FRCH.7",
      bookName: "Charism and Gift of Collaboration the",
      author: "Seamus",
    },
    {
      accountNumber: "211",
      callNumber: "3.11.FRST.8",
      bookName: "Statutes of the Sacretariats for the Missions",
      author: "Seamus",
    },
    {
      accountNumber: "212",
      callNumber: "3.11.FRFI.9",
      bookName: "Final Document of the XVIII General Assembly",
      author: "Seamus",
    },
    {
      accountNumber: "213",
      callNumber: "3.11.FRFI.10",
      bookName: "Final Document of the XVIII General Assembly",
      author: "Seamus",
    },
    {
      accountNumber: "214",
      callNumber: "3.11.FRGI.11",
      bookName: "Gift The, Jesus Christ, the one Saviour of the World, Yest",
      author: "Seamus",
    },
    {
      accountNumber: "215",
      callNumber: "3.11.FRDE.12",
      bookName: "Decree of Transformation",
      author: "Seamus",
    },
    {
      accountNumber: "216",
      callNumber: "3.11.FRIV.13",
      bookName: "VII Consultative Congress of Major Superiors, Konstanci",
      author: "Seamus",
    },
    {
      accountNumber: "217",
      callNumber: "3.11.FRIV.14",
      bookName: "VII Consultative Congress of Major Superiors, Konstanci",
      author: "Seamus",
    },
    {
      accountNumber: "218",
      callNumber: "3.11.FRVI.15",
      bookName: "VII Congresso Consultivo dei Superiori Maggiori, Konsta",
      author: "Seamus",
    },
    {
      accountNumber: "219",
      callNumber: "3.11.FRGE.16",
      bookName: "General Statutes, Union of Catholic Apostolate",
      author: "Seamus",
    },
    {
      accountNumber: "220",
      callNumber: "3.11.FRGE.17",
      bookName: "General Statutes, Union of Catholic Apostolate",
      author: "Seamus",
    },
    {
      accountNumber: "221",
      callNumber: "3.11.FRLE.18",
      bookName: "Letter of Convocation of the XIX General Assembly, Ro",
      author: "Seamus",
    },
    {
      accountNumber: "222",
      callNumber: "3.11.FRLE.19",
      bookName: "Letter of Convocation of the XIX General Assembly, Ro",
      author: "Seamus",
    },
    {
      accountNumber: "223",
      callNumber: "3.11.FRLE.20",
      bookName: "Letter of Convocation of the XIX General Assembly, Ro",
      author: "Seamus",
    },
    {
      accountNumber: "224",
      callNumber: "3.11.FRGE.21",
      bookName: "General Statutes, Union of Catholic Apostolate",
      author: "Seamus",
    },
    {
      accountNumber: "225",
      callNumber: "3.11.FRDA.22",
      bookName: "Das Charisma Des H. Vinzenz Pallotti",
      author: "Seamus",
    },
    {
      accountNumber: "226",
      callNumber: "3.11.FRIL.23",
      bookName: "Il Carism de S. Vincenzo Pallotti",
      author: "Seamus",
    },
    {
      accountNumber: "227",
      callNumber: "3.11.KRFI.24",
      bookName: "Final Document of the XIX General Assembly",
      author: "Friedrich",
    },
    {
      accountNumber: "228",
      callNumber: "3.11.KRFI.25",
      bookName: "Final Document of the XIX General Assembly",
      author: "Friedrich",
    },
    {
      accountNumber: "229",
      callNumber: "3.11.KRFI.26",
      bookName: "Final Document of the XIX General Assembly",
      author: "Friedrich",
    },
    {
      accountNumber: "230",
      callNumber: "3.11.KRFI.27",
      bookName: "Final Document of the XIX General Assembly",
      author: "Friedrich",
    },
    {
      accountNumber: "231",
      callNumber: "3.11.KRFI.28",
      bookName: "Final Document of the XIX General Assembly",
      author: "Friedrich",
    },
    {
      accountNumber: "232",
      callNumber: "3.11.KRFI.29",
      bookName: "Final Document of the XIX General Assembly",
      author: "Friedrich",
    },
    {
      accountNumber: "233",
      callNumber: "3.11.JUME.30",
      bookName: "Message to the Members of the Society",
      author: "Martin",
    },
    {
      accountNumber: "234",
      callNumber: "3.11.JUON.31",
      bookName: "One Hundred and Fifty Years of the Union of the Catholic",
      author: "Martin",
    },
    {
      accountNumber: "235",
      callNumber: "3.11.JNAP.32",
      bookName: "Apostolate of the Society Today The",
      author: "Martin",
    },
    {
      accountNumber: "236",
      callNumber: "3.11.FRTO.33",
      bookName: "Together we Journey Together we serve",
      author: "Seamus",
    },
    {
      accountNumber: "237",
      callNumber: "3.11.FRIN.34",
      bookName: "In the Union to Evangelize",
      author: "Seamus",
    },
    {
      accountNumber: "238",
      callNumber: "3.11.FRME.35",
      bookName: "Memory and Prophecy of the Union of Catholic Apostola",
      author: "Seamus",
    },
    {
      accountNumber: "239",
      callNumber: "3.11.FRBR.36",
      bookName: "Brothers of the Society of the Catholic Apostolate The",
      author: "Seamus",
    },
    {
      accountNumber: "240",
      callNumber: "3.11.FRIN.37",
      bookName: "In a Constant Search for Faithfulness",
      author: "Seamus",
    },
    {
      accountNumber: "241",
      callNumber: "3.11.FRCH.38",
      bookName: "Charism and Gift of Collaboration The",
      author: "Seamus",
    },
    {
      accountNumber: "242",
      callNumber: "3.11.FRFI.39",
      bookName: "Final Document of the XVIII General Assembly",
      author: "Seamus",
    },
    {
      accountNumber: "243",
      callNumber: "3.11.FRGE.40",
      bookName: "General Statutes, Union of Catholic Apostolate",
      author: "Seamus",
    },
    {
      accountNumber: "244",
      callNumber: "3.11.FRCH.41",
      bookName: "Charism of St. Vincent Pallotti",
      author: "Seamus",
    },
    {
      accountNumber: "245",
      callNumber: "3.11.SOIN.42",
      bookName: "In A Constant Search for Faithfulness",
      author: "Society of the Catholic Apostola",
    },
    {
      accountNumber: "246",
      callNumber: "3.11.SOGI.43",
      bookName: "Gift The",
      author: "Society of the Catholic Apostola",
    },
    {
      accountNumber: "247",
      callNumber: "3.11.SOCO.44",
      bookName: "Contemplation of Life in the Spirit of the Cenacle",
      author: "Society of the Catholic Apostola",
    },
    {
      accountNumber: "248",
      callNumber: "3.11.SOIU.45",
      bookName: "Ius Additicium",
      author: "Societatis Apostolatus Catholici",
    },
    {
      accountNumber: "249",
      callNumber: "3.11.SOIU.46",
      bookName: "Ius Additicium",
      author: "Societatis Apostolatus Catholici",
    },
    {
      accountNumber: "250",
      callNumber: "3.11.SOFI.47",
      bookName: "Final Document of the XIX General Assembly",
      author: "Society of the Catholic Apostola",
    },
    {
      accountNumber: "251",
      callNumber: "3.11.JUL.48",
      bookName: "L`Apostolato Della Societa Oggi",
      author: "Martin",
    },
    {
      accountNumber: "252",
      callNumber: "3.11.SOIN.49",
      bookName: "In the Union to Evangelize",
      author: "Society of the Catholic Apostola",
    },
    {
      accountNumber: "253",
      callNumber: "3.11.SOIN.50",
      bookName: "In the Union to Evangelize",
      author: "Society of the Catholic Apostola",
    },
    {
      accountNumber: "254",
      callNumber: "3.11.FRIN.51",
      bookName: "In un Dinamismo di Fedelta",
      author: "Seamus",
    },
    {
      accountNumber: "255",
      callNumber: "3.11.FRCH.52",
      bookName: "Charism and Gift of Collaboration The",
      author: "Seamus",
    },
    {
      accountNumber: "256",
      callNumber: "3.11.FRDO.53",
      bookName: "Documento Finale Della XVIII Assemblea Generale",
      author: "Seamus",
    },
    {
      accountNumber: "257",
      callNumber: "3.11.FRFI.54",
      bookName: "Final Document of the XVIII General Assembly",
      author: "Seamus",
    },
    {
      accountNumber: "258",
      callNumber: "3.11.SOPO.55",
      bookName: "To Revive faith and Rekindle charity: Apostles of Jesus in",
      author: "Society of the Catholic Apostola",
    },
    {
      accountNumber: "259",
      callNumber: "3.11.SOFI.56",
      bookName: "Final Document of the XX General Assembly, Ariccia, 20",
      author: "Society of the Catholic Apostola",
    },
    {
      accountNumber: "260",
      callNumber: "3.11.SOSO.57",
      bookName: "Society of the Catholic Apostolate",
      author: "Society of the Catholic Apostola",
    },
    {
      accountNumber: "261",
      callNumber: "3.11.SOCO.58",
      bookName: "Contemplation of Life in the Spirit of the Cenacle - Societ",
      author: "Society of the Catholic Apostola",
    },
    {
      accountNumber: "262",
      callNumber: "4.10.KOST.1",
      bookName: "St. Vincent Pallotti",
      author: "Augustine Kolencherry",
    },
    {
      accountNumber: "263",
      callNumber: "4.10.WAST.2",
      bookName: "St. Vincent Pallotti - A Prophet for Our Time",
      author: "Hans",
    },
    {
      accountNumber: "264",
      callNumber: "4.10.AMSA.3",
      bookName: "San Vincenzo Pallotti",
      author: "Francesco",
    },
    {
      accountNumber: "265",
      callNumber: "4.10.KOFI.4",
      bookName: "Fire and Flame",
      author: "Augustine Kolencherry",
    },
    {
      accountNumber: "266",
      callNumber: "4.10.MELI.5",
      bookName: "Life of the Servant of God Vincent Pallotti of Rome, The",
      author: "Raphael",
    },
    {
      accountNumber: "267",
      callNumber: "4.10.MELI.6",
      bookName: "Life of the Servant of God Vincent Pallotti of Rome, The",
      author: "Raphael",
    },
    {
      accountNumber: "268",
      callNumber: "4.10.WIST.7",
      bookName: "Story of Vincent Pallotti, Formerly `Give me Souls`",
      author: "Nicholas M.",
    },
    {
      accountNumber: "269",
      callNumber: "4.10.WIST.8",
      bookName: "Story of Vincent Pallotti, Formerly `Give me Souls`",
      author: "Nicholas M.",
    },
    {
      accountNumber: "270",
      callNumber: "4.10.WIST.9",
      bookName: "Story of Vincent Pallotti, Formerly `Give me Souls`",
      author: "Nicholas M.",
    },
    {
      accountNumber: "271",
      callNumber: "4.10.WIST.10",
      bookName: "Story of Vincent Pallotti, Formerly `Give me Souls`",
      author: "Nicholas M.",
    },
    {
      accountNumber: "272",
      callNumber: "4.10.HAVI.11",
      bookName: "Vincent Pallotti Apostle and Lover",
      author: "Corinne R.",
    },
    {
      accountNumber: "273",
      callNumber: "4.10.HAVI.12",
      bookName: "Vincent Pallotti Apostle and Lover",
      author: "Corinne R.",
    },
    {
      accountNumber: "274",
      callNumber: "4.10.HAVI.13",
      bookName: "Vincent Pallotti Apostle and Lover",
      author: "Corinne R.",
    },
    {
      accountNumber: "275",
      callNumber: "4.10.HAVI.14",
      bookName: "Vincent Pallotti Apostle and Lover",
      author: "Corinne R.",
    },
    {
      accountNumber: "276",
      callNumber: "4.10.WESA.15",
      bookName: "Saint Vincent Pallotti - Apostle and Mystic",
      author: "Eugene",
    },
    {
      accountNumber: "277",
      callNumber: "4.10.WESA.16",
      bookName: "Saint Vincent Pallotti - Apostle and Mystic",
      author: "Eugene",
    },
    {
      accountNumber: "278",
      callNumber: "4.10.WESA.17",
      bookName: "Saint Vincent Pallotti - Apostle and Mystic",
      author: "Eugene",
    },
    {
      accountNumber: "279",
      callNumber: "4.10.WESA.18",
      bookName: "Saint Vincent Pallotti - Apostle and Mystic",
      author: "Eugene",
    },
    {
      accountNumber: "280",
      callNumber: "4.10.WAST.19",
      bookName: "St. Vincent Pallotti - A Prophet for Our Time",
      author: "Hans",
    },
    {
      accountNumber: "281",
      callNumber: "4.10.WAST.20",
      bookName: "St. Vincent Pallotti - A Prophet for Our Time",
      author: "Hans",
    },
    {
      accountNumber: "282",
      callNumber: "4.10.WAST.21",
      bookName: "St. Vincent Pallotti - A Prophet for Our Time",
      author: "Hans",
    },
    {
      accountNumber: "283",
      callNumber: "4.10.WAST.22",
      bookName: "St. Vincent Pallotti - A Prophet for Our Time",
      author: "Hans",
    },
    {
      accountNumber: "284",
      callNumber: "4.10.WAST.23",
      bookName: "St. Vincent Pallotti - A Prophet for Our Time",
      author: "Hans",
    },
    {
      accountNumber: "285",
      callNumber: "4.10.WAST.24",
      bookName: "St. Vincent Pallotti - A Prophet for Our Time",
      author: "Hans",
    },
    {
      accountNumber: "286",
      callNumber: "4.10.WAST.25",
      bookName: "St. Vincent Pallotti - A Prophet for Our Time",
      author: "Hans",
    },
    {
      accountNumber: "287",
      callNumber: "4.10.WAST.26",
      bookName: "St. Vincent Pallotti - A Prophet for Our Time",
      author: "Hans",
    },
    {
      accountNumber: "288",
      callNumber: "4.10.WAST.27",
      bookName: "St. Vincent Pallotti - A Prophet for Our Time",
      author: "Hans",
    },
    {
      accountNumber: "289",
      callNumber: "4.10.WAST.28",
      bookName: "St. Vincent Pallotti - A Prophet for Our Time",
      author: "Hans",
    },
    {
      accountNumber: "290",
      callNumber: "4.10.WAST.29",
      bookName: "St. Vincent Pallotti - A Prophet for Our Time",
      author: "Hans",
    },
    {
      accountNumber: "291",
      callNumber: "4.10.WAST.30",
      bookName: "St. Vincent Pallotti - A Prophet for Our Time",
      author: "Hans",
    },
    {
      accountNumber: "292",
      callNumber: "4.10.WAST.31",
      bookName: "St. Vincent Pallotti - A Prophet for Our Time",
      author: "Hans",
    },
    {
      accountNumber: "293",
      callNumber: "4.10.WAST.32",
      bookName: "St. Vincent Pallotti - A Prophet for Our Time",
      author: "Hans",
    },
    {
      accountNumber: "294",
      callNumber: "4.10.WAST.33",
      bookName: "St. Vincent Pallotti - A Prophet for Our Time",
      author: "Hans",
    },
    {
      accountNumber: "295",
      callNumber: "4.10.WAST.34",
      bookName: "St. Vincent Pallotti - A Prophet for Our Time",
      author: "Hans",
    },
    {
      accountNumber: "296",
      callNumber: "4.10.WAST.35",
      bookName: "St. Vincent Pallotti - A Prophet for Our Time",
      author: "Hans",
    },
    {
      accountNumber: "297",
      callNumber: "4.10.WAST.36",
      bookName: "St. Vincent Pallotti - A Prophet for Our Time",
      author: "Hans",
    },
    {
      accountNumber: "298",
      callNumber: "4.10.WAST.37",
      bookName: "St. Vincent Pallotti - A Prophet for Our Time",
      author: "Hans",
    },
    {
      accountNumber: "299",
      callNumber: "4.10.WAST.38",
      bookName: "St. Vincent Pallotti - A Prophet for Our Time",
      author: "Hans",
    },
    {
      accountNumber: "300",
      callNumber: "4.10.WAST.39",
      bookName: "St. Vincent Pallotti - A Prophet for Our Time",
      author: "Hans",
    },
    {
      accountNumber: "301",
      callNumber: "4.10.WAST.40",
      bookName: "St. Vincent Pallotti - A Prophet for Our Time",
      author: "Hans",
    },
    {
      accountNumber: "302",
      callNumber: "4.10.DRST.41",
      bookName: "St. Vincent Pallotti",
      author: "Alex ed.",
    },
    {
      accountNumber: "303",
      callNumber: "4.10.DRST.42",
      bookName: "St. Vincent Pallotti",
      author: "Alex ed.",
    },
    {
      accountNumber: "304",
      callNumber: "4.10.DRST.43",
      bookName: "St. Vincent Pallotti",
      author: "Alex ed.",
    },
    {
      accountNumber: "305",
      callNumber: "4.10.PIII.44",
      bookName: "II Beato Vincenzo Pallotti",
      author: "Domenico",
    },
    {
      accountNumber: "306",
      callNumber: "4.10.PIII.45",
      bookName: "II Beato Vincenzo Pallotti",
      author: "Domenico",
    },
    {
      accountNumber: "307",
      callNumber: "4.10.AMIL.46",
      bookName: "Il Beato Vincenzp Pallotti",
      author: "Francesco",
    },
    {
      accountNumber: "308",
      callNumber: "4.10.PISA.47",
      bookName: "San Vincenzo Pallotti",
      author: "Domenico",
    },
    {
      accountNumber: "309",
      callNumber: "4.10.FEUN.48",
      bookName: "Un Operaio Della Vigna",
      author: "Icilio",
    },
    {
      accountNumber: "310",
      callNumber: "4.10.HEDA.49",
      bookName: "Das Unendliche Herz",
      author: "Alois",
    },
    {
      accountNumber: "311",
      callNumber: "4.10.STDE.50",
      bookName: "Der Tag des Neven Heiligen",
      author: "Karl",
    },
    {
      accountNumber: "312",
      callNumber: "4.10.VAVI.51",
      bookName: "Vincenzo Pallotti",
      author: "Luigi",
    },
    {
      accountNumber: "313",
      callNumber: "4.10.GALI.52",
      bookName: "Life of St. Vincent Pallotti The",
      author: "John S.",
    },
    {
      accountNumber: "314",
      callNumber: "4.10.MISA.53",
      bookName: "Sao Vicente Pallotti",
      author: "Agostinho",
    },
    {
      accountNumber: "315",
      callNumber: "4.10.BOYE.54",
      bookName: "Yearning of a Soul",
      author: "Flavian",
    },
    {
      accountNumber: "316",
      callNumber: "4.10.ZIVI.55",
      bookName: "Vinzenz Pallotti",
      author: "August",
    },
    {
      accountNumber: "317",
      callNumber: "4.10.MOSA.56",
      bookName: "San Vincenzo Pallotti",
      author: "Francesco",
    },
    {
      accountNumber: "318",
      callNumber: "4.10.FABH.57",
      bookName: "Bhagevont Vincent Pallotti",
      author: "Planton Faria",
    },
    {
      accountNumber: "319",
      callNumber: "4.10.HOVI.58",
      bookName: "Vicente Pallotti",
      author: "Alexander",
    },
    {
      accountNumber: "320",
      callNumber: "4.10.WEVI.59",
      bookName: "Vinzenz Pallotti Ein Apostel und Mystiker",
      author: "Eugen",
    },
    {
      accountNumber: "321",
      callNumber: "4.10.DOTR.60",
      bookName: "Traum von einer lebendigen Kirche",
      author: "Anton ed.",
    },
    {
      accountNumber: "322",
      callNumber: "4.10.PEDE.61",
      bookName: "Der Heilige Vom Tiberuffer",
      author: "Heinz",
    },
    {
      accountNumber: "323",
      callNumber: "4.10.STVI.62",
      bookName: "Vinzenz Pallotti",
      author: "Karl",
    },
    {
      accountNumber: "324",
      callNumber: "4.10.WIAP.63",
      bookName: "Apostle in Action St Vincent Pallotti",
      author: "John",
    },
    {
      accountNumber: "325",
      callNumber: "4.10.WIAP.64",
      bookName: "Apostle in Action St Vincent Pallotti",
      author: "John",
    },
    {
      accountNumber: "326",
      callNumber: "4.10.WIAP.65",
      bookName: "Apostle in Action St Vincent Pallotti",
      author: "John",
    },
    {
      accountNumber: "327",
      callNumber: "4.10.WIAP.66",
      bookName: "Apostle in Action St Vincent Pallotti",
      author: "John",
    },
    {
      accountNumber: "328",
      callNumber: "4.10.UNSP.67",
      bookName: "St. Vincent Pallotti",
      author: "Union of the Catholic Apostolate",
    },
    {
      accountNumber: "329",
      callNumber: "4.10.UNSP.68",
      bookName: "St. Vincent Pallotti",
      author: "Union of the Catholic Apostolate",
    },
    {
      accountNumber: "330",
      callNumber: "4.10.KOVI.69",
      bookName: "Visudha Vincent Pallotti",
      author: "Augustine Kolencherry",
    },
    {
      accountNumber: "331",
      callNumber: "4.10.UNST.70",
      bookName: "St. Vincent Pallotti",
      author: "Union of the Catholic Apostolate",
    },
    {
      accountNumber: "332",
      callNumber: "4.10.GALI.71",
      bookName: "Life of St. Vincent Pallotti The",
      author: "John S. Gaynor",
    },
    {
      accountNumber: "333",
      callNumber: "4.10.GASA.72",
      bookName: "Saint Vincent Pallotti",
      author: "John S. Gaynor",
    },
    {
      accountNumber: "334",
      callNumber: "4.10.GASA.73",
      bookName: "Saint Vincent Pallotti",
      author: "John S. Gaynor",
    },
    {
      accountNumber: "335",
      callNumber: "4.10.GASA.74",
      bookName: "Saint Vincent Pallotti",
      author: "John S. Gaynor",
    },
    {
      accountNumber: "336",
      callNumber: "4.10.GASA.75",
      bookName: "Saint Vincent Pallotti",
      author: "John S. Gaynor",
    },
    {
      accountNumber: "337",
      callNumber: "4.10.GASA.76",
      bookName: "Saint Vincent Pallotti",
      author: "John S. Gaynor",
    },
    {
      accountNumber: "338",
      callNumber: "4.10.MELI.77",
      bookName: "Life of the Servant of God Vincent Pallotti of Rome, The",
      author: "Raphael",
    },
    {
      accountNumber: "339",
      callNumber: "4.10.WIST.78",
      bookName: "Story of Vincent Pallotti The",
      author: "Nicholas M. Wilwers",
    },
    {
      accountNumber: "340",
      callNumber: "4.10.CELO.79",
      bookName: "Love of Christ Urges Us On A",
      author: "Central Thread The",
    },
    {
      accountNumber: "341",
      callNumber: "4.10.GASA.80",
      bookName: "Saint Vincent Pallotti",
      author: "John S. Gaynor",
    },
    {
      accountNumber: "342",
      callNumber: "4.10.HAP.81",
      bookName: "Apostle of God`s Mercy",
      author: "H. F. Publications",
    },
    {
      accountNumber: "343",
      callNumber: "4.10.HAP.82",
      bookName: "Apostle of God`s Mercy",
      author: "Society of the Catholic Apostola",
    },
    {
      accountNumber: "344",
      callNumber: "4.10.KOST.83",
      bookName: "St. Vincent Pallotti",
      author: "Augustine Kolencherry",
    },
    {
      accountNumber: "345",
      callNumber: "4.10.HAP.84",
      bookName: "Apostle of God`s Mercy",
      author: "St. Vincent Pallotti",
    },
    {
      accountNumber: "346",
      callNumber: "4.10.KOST.85",
      bookName: "St. Vincent Pallotti",
      author: "Augustine Kolencherry",
    },
    {
      accountNumber: "347",
      callNumber: "5.10.SOIN.1",
      bookName: "In Memoriam Defuncti",
      author: "Society of the Catholic Apostola",
    },
    {
      accountNumber: "348",
      callNumber: "5.10.SOIN.2",
      bookName: "In Memoriam Defuncti",
      author: "Society of the Catholic Apostola",
    },
    {
      accountNumber: "349",
      callNumber: "5.10.BAPA.3",
      bookName: "Paul de Geslin, Compagnon de Saint Vincent Pallotti",
      author: "Bruno ed.",
    },
    {
      accountNumber: "350",
      callNumber: "5.10.BAPA.4",
      bookName: "Paul de Geslin, Compagnon de Saint Vincent Pallotti",
      author: "Bruno ed.",
    },
    {
      accountNumber: "351",
      callNumber: "5.10.AMEL.5",
      bookName: "Elisabetta Sanna",
      author: "Francesco",
    },
    {
      accountNumber: "352",
      callNumber: "5.10.AMEL.6",
      bookName: "Elisabetta Sanna",
      author: "Francesco",
    },
    {
      accountNumber: "353",
      callNumber: "5.10.PRSA.7",
      bookName: "Sam Veshenth Pallothi (Konkani)",
      author: "Maurice A.",
    },
    {
      accountNumber: "354",
      callNumber: "5.10.ORCO.8",
      bookName: "Compendio Della Vita Elisabetta Sanna",
      author: "Carlo Maria",
    },
    {
      accountNumber: "355",
      callNumber: "5.10.AMEL.9",
      bookName: "Mamma Sanna",
      author: "Francesco",
    },
    {
      accountNumber: "356",
      callNumber: "5.10.BAPA.10",
      bookName: "Paul De Geslin Compagnon De Saint Vincene Pallotti",
      author: "Bruno ed.",
    },
    {
      accountNumber: "357",
      callNumber: "5.10.HOGO.11",
      bookName: "God in All Things",
      author: "Francis Werner",
    },
    {
      accountNumber: "358",
      callNumber: "5.10.CZHE.12",
      bookName: "He loved to the End",
      author: "Ryszard",
    },
    {
      accountNumber: "359",
      callNumber: "5.10.CZHE.13",
      bookName: "He Loved to the End",
      author: "Ryszard",
    },
    {
      accountNumber: "360",
      callNumber: "5.10.AMEL.14",
      bookName: "Elisabetta Sanna",
      author: "Francesco",
    },
    {
      accountNumber: "361",
      callNumber: "5.10.CZHE.15",
      bookName: "He Loved to the End",
      author: "Ryszard",
    },
    {
      accountNumber: "362",
      callNumber: "5.10.AMEL.16",
      bookName: "Elisabetta Sanna",
      author: "Amoroso F.",
    },
    {
      accountNumber: "363",
      callNumber: "5.10.BYHA.17",
      bookName: "Hard Road A",
      author: "Francis Byrne",
    },
    {
      accountNumber: "364",
      callNumber: "5.10.AMEL.18",
      bookName: "Elisabetta Sanna",
      author: "Amoroso F.",
    },
    {
      accountNumber: "365",
      callNumber: "5.10.MULE.19",
      bookName: "Legacy of Love A",
      author: "Mulholland B.",
    },
    {
      accountNumber: "366",
      callNumber: "5.10.CULE.20",
      bookName: "Let by the Spirit",
      author: "John Luemmen",
    },
    {
      accountNumber: "367",
      callNumber: "6.10.RECA.1",
      bookName: "Catalogus 2000",
      author: "Rector General",
    },
    {
      accountNumber: "368",
      callNumber: "6.10.RECA.2",
      bookName: "Catalogus 2003",
      author: "Rector General",
    },
    {
      accountNumber: "369",
      callNumber: "6.10.OSSU.3",
      bookName: "Sueddeutsche Pallottinerprovinz und Regio Uruguay",
      author: "Osterreichische Regio",
    },
    {
      accountNumber: "370",
      callNumber: "6.10.OSSU.4",
      bookName: "Sueddeutsche Pallottinerprovinz und Regio Uruguay",
      author: "Osterreichische Regio.  publish.",
    },
    {
      accountNumber: "371",
      callNumber: "6.10.CUCA.5",
      bookName: "Catalogus Societatis Apostolatus Catholici 1979",
      author: "Curia Generali,Ad SS, Salvatore",
    },
    {
      accountNumber: "372",
      callNumber: "6.10.CUCA.6",
      bookName: "Catalogus Societatis Apostolatus Catholici",
      author: "Curia Generali,Ad SS, Salvatore",
    },
    {
      accountNumber: "373",
      callNumber: "6.10.CUCA.7",
      bookName: "Catalogus Societatis Apostolatus Catholici",
      author: "Curia Generali,Ad SS, Salvatore",
    },
    {
      accountNumber: "374",
      callNumber: "6.10.PRSA.8",
      bookName: "SAC Directory 2008 India",
      author: "Prabhu Prakash Province",
    },
    {
      accountNumber: "375",
      callNumber: "6.10.SOCA.9",
      bookName: "Catalogo 2009",
      author: "Societa dell Apostolato Cattolico",
    },
    {
      accountNumber: "376",
      callNumber: "6.10.SOCA.10",
      bookName: "Catalogo 2009",
      author: "Societa dell Apostolato Cattolico",
    },
    {
      accountNumber: "377",
      callNumber: "6.10.SOCA.11",
      bookName: "Catalogo 2012",
      author: "Societa dell Apostolato Cattolico",
    },
    {
      accountNumber: "378",
      callNumber: "6.10.SADI.12",
      bookName: "SAC Directory 2005 India",
      author: "Prabhu Prakash Province",
    },
    {
      accountNumber: "379",
      callNumber: "6.10.SACA.13",
      bookName: "Catalogus 1997",
      author: "Societa dell Apostolato Cattolico",
    },
    {
      accountNumber: "380",
      callNumber: "6.10.RECA.14",
      bookName: "Catalogus 2003",
      author: "Rector General",
    },
    {
      accountNumber: "381",
      callNumber: "6.10.RECA.15",
      bookName: "Catalogus 2009",
      author: "Rector General",
    },
    {
      accountNumber: "382",
      callNumber: "6.10.SOCA.16",
      bookName: "Catalogus 2006",
      author: "Societa dell Apostolato Cattolico",
    },
    {
      accountNumber: "383",
      callNumber: "8.11.SORA.1",
      bookName: "Ratio Institutionis of the Society of the Catholic Apostolat",
      author: "Society of the Catholic Apostola",
    },
    {
      accountNumber: "384",
      callNumber: "8.11.SORA.2",
      bookName: "Ratio Institutionis of the Society of the Catholic Apostolat",
      author: "Society of the Catholic Apostola",
    },
    {
      accountNumber: "385",
      callNumber: "8.11.SORA.3",
      bookName: "Ratio Institutionis of the Society of the Catholic Apostolat",
      author: "Society of the Catholic Apostola",
    },
    {
      accountNumber: "386",
      callNumber: "8.11.SORA.4",
      bookName: "Ratio Institutionis of the Society of the Catholic Apostolat",
      author: "Society of the Catholic Apostola",
    },
    {
      accountNumber: "387",
      callNumber: "8.11.SORA.5",
      bookName: "Ratio Institutionis of the Society of the Catholic Apostolat",
      author: "Society of the Catholic Apostola",
    },
    {
      accountNumber: "388",
      callNumber: "8.11.THPA.6",
      bookName: "Pallottine Identity and Formation",
      author: "Mathew Augustine",
    },
    {
      accountNumber: "389",
      callNumber: "8.11.SOGE.7",
      bookName: "General Guidelines for Formation in the Introductoru Peri",
      author: "Societas Apostolatus Catholici",
    },
    {
      accountNumber: "390",
      callNumber: "8.11.APRA.8",
      bookName: "Ratio Educandi Societatis Apostolatus Catholici",
      author: "Apud Curiam Generalem",
    },
    {
      accountNumber: "391",
      callNumber: "8.11.SOFO.9",
      bookName: "Formation Manual for the Pallottines in India",
      author: "Society of the Catholic Apostola",
    },
    {
      accountNumber: "392",
      callNumber: "8.11.SORA.10",
      bookName: "Ratio Institutionis of the Society of the Catholic Apostolat",
      author: "Society of the Catholic Apostola",
    },
    {
      accountNumber: "393",
      callNumber: "8.10.GEFI.1",
      bookName: "Find the God Who Seeks You",
      author: "Vensus George",
    },
    {
      accountNumber: "394",
      callNumber: "8.10.FRSA.2",
      bookName: "SAC Renewal 2000",
      author: "Seamus",
    },
    {
      accountNumber: "395",
      callNumber: "8.10.AMSA.3",
      bookName: "SAC Renewal 2000",
      author: "Francesco",
    },
    {
      accountNumber: "396",
      callNumber: "8.10.HESA.4",
      bookName: "SAC Renewal 2000",
      author: "Hoser",
    },
    {
      accountNumber: "397",
      callNumber: "8.10.HWSA.5",
      bookName: "SAC Renewal 2000",
      author: "Hoser",
    },
    {
      accountNumber: "398",
      callNumber: "8.10.KRSA.6",
      bookName: "SAC Renewal 2000",
      author: "Friedrich",
    },
    {
      accountNumber: "399",
      callNumber: "8.10.KRSA.7",
      bookName: "SAC Renewal 2000",
      author: "Friedrich",
    },
    {
      accountNumber: "400",
      callNumber: "8.10.KRSA.8",
      bookName: "SAC Renewal 2000",
      author: "Friedrich",
    },
    {
      accountNumber: "401",
      callNumber: "8.10.QUSA.9",
      bookName: "SAC Renewal 2000",
      author: "Quaini J.",
    },
    {
      accountNumber: "402",
      callNumber: "8.10.QUSA.10",
      bookName: "SAC Renewal 2000",
      author: "Quaini J.",
    },
    {
      accountNumber: "403",
      callNumber: "8.10.QUSA.11",
      bookName: "SAC Renewal 2000",
      author: "Quaini J.",
    },
    {
      accountNumber: "404",
      callNumber: "8.10.ANSA.12",
      bookName: "SAC Renewal 2000",
      author: "Henry",
    },
    {
      accountNumber: "405",
      callNumber: "8.10.ANSA.13",
      bookName: "SAC Renewal 2000",
      author: "Henry",
    },
    {
      accountNumber: "406",
      callNumber: "8.10.ANSA.14",
      bookName: "SAC Renewal 2000",
      author: "Henry",
    },
    {
      accountNumber: "407",
      callNumber: "8.10.ANSA.15",
      bookName: "SAC Renewal 2000",
      author: "Miguel",
    },
    {
      accountNumber: "408",
      callNumber: "8.10.ANSA.16",
      bookName: "SAC Renewal 2000",
      author: "Miguel",
    },
    {
      accountNumber: "409",
      callNumber: "8.10.ANSA.17",
      bookName: "SAC Renewal 2000",
      author: "Miguel",
    },
    {
      accountNumber: "410",
      callNumber: "8.10.HASA.18",
      bookName: "SAC Renewal 2000",
      author: "Francois",
    },
    {
      accountNumber: "411",
      callNumber: "8.10.HASA.19",
      bookName: "SAC Renewal 2000 [8]",
      author: "Francois",
    },
    {
      accountNumber: "412",
      callNumber: "8.10.HASA.20",
      bookName: "SAC Renewal 2000 [8]",
      author: "Francois",
    },
    {
      accountNumber: "413",
      callNumber: "8.10.GEFI.21",
      bookName: "Find the God Who Seeks You",
      author: "Vensus George",
    },
    {
      accountNumber: "414",
      callNumber: "8.10.UNPA.22",
      bookName: "Spiritual Guide, A New Beginning for New Times, Pallott",
      author: "Union of the Catholic Apostolate",
    },
    {
      accountNumber: "415",
      callNumber: "8.10.JADI.23",
      bookName: "Die Liebe Christi Draengt Uns",
      author: "Pat",
    },
    {
      accountNumber: "416",
      callNumber: "8.10.JAEM.24",
      bookName: "Empowered by Love",
      author: "Pat",
    },
    {
      accountNumber: "417",
      callNumber: "8.10.BOYE.25",
      bookName: "Yearning of a Soul",
      author: "Flavian",
    },
    {
      accountNumber: "418",
      callNumber: "8.10.BOSO.26",
      bookName: "Soul of a Saint",
      author: "Flavian",
    },
    {
      accountNumber: "419",
      callNumber: "8.10.BOSO.27",
      bookName: "Soul of a Saint",
      author: "Flavian",
    },
    {
      accountNumber: "420",
      callNumber: "8.10.BOSO.28",
      bookName: "Soul of a Saint",
      author: "Flavian",
    },
    {
      accountNumber: "421",
      callNumber: "8.10.AMUN.29",
      bookName: "Union With Jesus",
      author: "Francesco",
    },
    {
      accountNumber: "422",
      callNumber: "8.10.AMUN.30",
      bookName: "Union With Jesus",
      author: "Francesco",
    },
    {
      accountNumber: "423",
      callNumber: "8.10.GEAO.31",
      bookName: "Authentic Human Person The",
      author: "Vensus George",
    },
    {
      accountNumber: "424",
      callNumber: "8.10.WIST.32",
      bookName: "St. Vincent Pallotti - Apostle in Action",
      author: "John",
    },
    {
      accountNumber: "425",
      callNumber: "8.10.WST.33",
      bookName: "St. Vincent Pallotti Apostle in Action",
      author: "John",
    },
    {
      accountNumber: "426",
      callNumber: "8.10.WIST.34",
      bookName: "St. Vincent Pallotti   Apostle in Action",
      author: "John",
    },
    {
      accountNumber: "427",
      callNumber: "8.10.AMUN.35",
      bookName: "Union With Jesus",
      author: "Francesco",
    },
    {
      accountNumber: "428",
      callNumber: "8.10.RAVI.36",
      bookName: "Vincenzo Pallotti E L`Ottavario Dell`Epifania",
      author: "Giuseppe",
    },
    {
      accountNumber: "429",
      callNumber: "8.10.RAVI.37",
      bookName: "Vincenzo Pallotti L`Ottavario Dell`Epifania",
      author: "Giuseppe",
    },
    {
      accountNumber: "430",
      callNumber: "8.10.HEDA.38",
      bookName: "Das Unendliche Herz",
      author: "Alois",
    },
    {
      accountNumber: "431",
      callNumber: "8.10.FADI.39",
      bookName: "Die Epiphaniefeier",
      author: "Ansgar",
    },
    {
      accountNumber: "432",
      callNumber: "8.10.GEAU.40",
      bookName: "Authentic Human Person The",
      author: "Vensus George",
    },
    {
      accountNumber: "433",
      callNumber: "8.10.PRKA.41",
      bookName: "Katholisches Apostolat Heute",
      author: "Manfred",
    },
    {
      accountNumber: "434",
      callNumber: "8.10.PRGL.42",
      bookName: "Glaube Hilft Leben",
      author: "Manfred ed.",
    },
    {
      accountNumber: "435",
      callNumber: "8.10.PRWI.43",
      bookName: "Wie mich der Vater gesandt hat, so sende ich euch",
      author: "Manfred ed.",
    },
    {
      accountNumber: "436",
      callNumber: "8.10.KODI.44",
      bookName: "Die Mutter Jesu Bei Vinzenz Pallotti nach seinen Gedruck",
      author: "Heinrich Maria",
    },
    {
      accountNumber: "437",
      callNumber: "8.10.ANFO.45",
      bookName: "Folge Mir",
      author: "Anonymous",
    },
    {
      accountNumber: "438",
      callNumber: "8.10.WYPA.46",
      bookName: "Pallottis Epiphaniefeier in Rom",
      author: "Arthur",
    },
    {
      accountNumber: "439",
      callNumber: "8.10.WAUN.47",
      bookName: "Unendliche Gott Und Das Nights Und Suende",
      author: "Albert Peter",
    },
    {
      accountNumber: "440",
      callNumber: "8.10.RIAP.48",
      bookName: "Apostolato Universale [16]",
      author: "Rivista semestrale dell`Istituto S.",
    },
    {
      accountNumber: "441",
      callNumber: "8.10.KUME.49",
      bookName: "Messaggi Omelie Discorsi Alla Famiglia Pallottina",
      author: "Jan ed.",
    },
    {
      accountNumber: "442",
      callNumber: "8.10.LUNA.50",
      bookName: "Natura Giuridica Della Societa Dell;Apostolato Cattolico",
      author: "Janusz",
    },
    {
      accountNumber: "443",
      callNumber: "8.10.WADE.51",
      bookName: "Der Unendliche Gott Und Das Nichts Und Suende",
      author: "Albert Peter",
    },
    {
      accountNumber: "444",
      callNumber: "8.10.FADI.52",
      bookName: "Die Epiphaniefeier",
      author: "Ansgar",
    },
    {
      accountNumber: "445",
      callNumber: "8.10.BOSA.53",
      bookName: "Saint Vincent Pallotti`s Legacy",
      author: "Flavian",
    },
    {
      accountNumber: "446",
      callNumber: "8.10.AMDA.54",
      bookName: "Dal Nulla Al Tutto",
      author: "Francesco",
    },
    {
      accountNumber: "447",
      callNumber: "8.10.KUME.55",
      bookName: "Messaggi, Omelie, Discorsi All Famiglia Pallottina",
      author: "Jan ed.",
    },
    {
      accountNumber: "448",
      callNumber: "8.10.KUME.56",
      bookName: "Messaggi Omelie Discorsi Alla Famiglia Pallottina",
      author: "John Paul II",
    },
    {
      accountNumber: "449",
      callNumber: "8.10.AMUN.57",
      bookName: "Union With Jesus",
      author: "Francesco",
    },
    {
      accountNumber: "450",
      callNumber: "8.10.JAEM.58",
      bookName: "Empowered By Love",
      author: "Pat",
    },
    {
      accountNumber: "451",
      callNumber: "8.10.JOCA.59",
      bookName: "Caritas Christi Urget Nos",
      author: "Margaret ed.",
    },
    {
      accountNumber: "452",
      callNumber: "8.10.BOSP.60",
      bookName: "Saint Vincent Pallotti`s Vision",
      author: "Flavian Bonifazi",
    },
    {
      accountNumber: "453",
      callNumber: "8.10.AMBL.61",
      bookName: "Blessed Vincent Pallotti 1850-1950",
      author: "Anonymous",
    },
    {
      accountNumber: "454",
      callNumber: "8.10.BOYE.62",
      bookName: "Yearning of a Soul",
      author: "Flavian Bonifazi",
    },
    {
      accountNumber: "455",
      callNumber: "8.10.TEST.63",
      bookName: "St. Vincent Pallotti`s Spirituality of the Priesthood and its",
      author: "Reginald Fabian",
    },
    {
      accountNumber: "456",
      callNumber: "4.10.MELI.86",
      bookName: "Life of the Servant of God Vincent Pallotti of Rome, The",
      author: "Raphael",
    },
    {
      accountNumber: "457",
      callNumber: "8.10.GEAU.64",
      bookName: "Authentic Human Person The",
      author: "Vensus George",
    },
    {
      accountNumber: "458",
      callNumber: "8.10.WADE.65",
      bookName: 'Der Unendliche Gott und Das "Nichts Und Suende"',
      author: "Albert Peter",
    },
    {
      accountNumber: "459",
      callNumber: "8.10.ANCA.66",
      bookName: "Caritas Christi Urget Nos",
      author: "Anonymous",
    },
    {
      accountNumber: "460",
      callNumber: "8.10.DEVI.67",
      bookName: "Vinzenz Pallotti",
      author: "Paul",
    },
    {
      accountNumber: "461",
      callNumber: "8.10.AMGR.68",
      bookName: "Griff Ins Grenzenlose",
      author: "Francesco",
    },
    {
      accountNumber: "462",
      callNumber: "8.10.FRI.69",
      bookName: "I Fratelli Della Societa Dell`Apostolato Cattolico",
      author: "Seamus",
    },
    {
      accountNumber: "463",
      callNumber: "8.10.WADE.70",
      bookName: 'Der Unendliche Gott und Das "Nichts Und Suende"',
      author: "Albert Peter",
    },
    {
      accountNumber: "464",
      callNumber: "8.10.FRBR..71",
      bookName: "Brothers of the Society of the Catholic Apostolate The",
      author: "Seamus",
    },
    {
      accountNumber: "465",
      callNumber: "8.10.GEFI.72",
      bookName: "Find the God Who Seeks You",
      author: "Vensus George",
    },
    {
      accountNumber: "466",
      callNumber: "8.10.BOSA.73",
      bookName: "Saint Vincent Pallotti`s Vision",
      author: "Flavian",
    },
    {
      accountNumber: "467",
      callNumber: "8.10.JAEM.74",
      bookName: "Empowered by Love",
      author: "Pat",
    },
    {
      accountNumber: "468",
      callNumber: "8.10.AMUN.75",
      bookName: "Union With Jesus",
      author: "Francesco",
    },
    {
      accountNumber: "469",
      callNumber: "8.10.BOSA.76",
      bookName: "Saint Vincent Pallotti`s Vision",
      author: "Flavian Bonifazi",
    },
    {
      accountNumber: "470",
      callNumber: "8.10.JAEM.77",
      bookName: "Empowered by Love",
      author: "Pat",
    },
    {
      accountNumber: "471",
      callNumber: "8.10.BOYE.78",
      bookName: "Yearning of a Soul",
      author: "Flavian Bonifazi",
    },
    {
      accountNumber: "472",
      callNumber: "8.10.AMUN.79",
      bookName: "Union with Jesus",
      author: "Francesco Amoroso",
    },
    {
      accountNumber: "473",
      callNumber: "8.10.GEFI.80",
      bookName: "Find the God Who Seeks You",
      author: "Vensus George",
    },
    {
      accountNumber: "474",
      callNumber: "8.10.AMUN.81",
      bookName: "Union With Jesus",
      author: "Francesco",
    },
    {
      accountNumber: "475",
      callNumber: "8.10.KOPA.82",
      bookName: "Pallottine Mysticism a Mystery of God`s Love",
      author: "Augustine Kolencherry",
    },
    {
      accountNumber: "476",
      callNumber: "8.10.BOSA.83",
      bookName: "Saint Vincent Pallotti`s Legacy",
      author: "Flavian",
    },
    {
      accountNumber: "477",
      callNumber: "8.10.BOOU.84",
      bookName: "Our Pallottine Heritage",
      author: "Flavian ed.",
    },
    {
      accountNumber: "478",
      callNumber: "8.10.BOOU.85",
      bookName: "Our Pallottine Heritage",
      author: "Flavian",
    },
    {
      accountNumber: "479",
      callNumber: "8.10.BOOU.86",
      bookName: "Our Pallottine Heritage",
      author: "Flavian",
    },
    {
      accountNumber: "480",
      callNumber: "8.10.AMUN.87",
      bookName: "Union with Jesus",
      author: "Francisco",
    },
    {
      accountNumber: "481",
      callNumber: "8.10.AMUN.88",
      bookName: "Union With Jesus",
      author: "Francesco",
    },
    {
      accountNumber: "482",
      callNumber: "8.10.GEAU.89",
      bookName: "Authentic Human Person the",
      author: "Vensus George",
    },
    {
      accountNumber: "483",
      callNumber: "8.10.JAEM.90",
      bookName: "Empowered By Love",
      author: "Pat",
    },
    {
      accountNumber: "484",
      callNumber: "8.10.TEST.91",
      bookName: "St. Vincent Pallotti`s Spirituality of the Priesthood and its",
      author: "Reginald Fabian",
    },
    {
      accountNumber: "485",
      callNumber: "8.10.BOYE.92",
      bookName: "Yearning of a Soul",
      author: "Flavian Bonifazi",
    },
    {
      accountNumber: "486",
      callNumber: "8.10.DHYO.93",
      bookName: "Youth Work and the Charism of St. Vincent Pallotti The",
      author: "James Justin Dhiraviam SAC",
    },
    {
      accountNumber: "487",
      callNumber: "8.10.GEAU.94",
      bookName: "Authentic Human Person The",
      author: "Vensus George",
    },
    {
      accountNumber: "488",
      callNumber: "8.10.KAMA.95",
      bookName: "May Devotion in the life of St. Vincent Pallotti",
      author: "Mathew Kanjiramkalayil SAC",
    },
    {
      accountNumber: "489",
      callNumber: "8.10.GEFI.96",
      bookName: "Find the God Who Seeks You",
      author: "Vensus George",
    },
    {
      accountNumber: "490",
      callNumber: "8.10.KOPA.97",
      bookName: "Pallottine -Mysticism a Mystery of God`s Love",
      author: "Augustine Kolencherry",
    },
    {
      accountNumber: "491",
      callNumber: "1.10.PAGO.125",
      bookName: "God, the Infinite Love",
      author: "St. Vincent Pallotti",
    },
    {
      accountNumber: "492",
      callNumber: "4.10.GALI.87",
      bookName: "The Life of St. Vincent Pallotti",
      author: "John S.Gaynor",
    },
    {
      accountNumber: "493",
      callNumber: "4.10PIVI.88",
      bookName: "Vincent Pallotti",
      author: "Domenico",
    },
    {
      accountNumber: "494",
      callNumber: "8.12.PASA.1",
      bookName: "Saint Vincent Pallotti and the Catholic Apostolate",
      author: "Vincent Pallotti",
    },
    {
      accountNumber: "495",
      callNumber: "8.12.PASA.2",
      bookName: "Saint Vincent Pallotti and the Catholic Apostolate",
      author: "Vincent Pallotti",
    },
    {
      accountNumber: "496",
      callNumber: "8.12.PASA.3",
      bookName: "Saint Vincent Pallotti and the Catholic Apostolate",
      author: "Vincent Pallotti",
    },
    {
      accountNumber: "497",
      callNumber: "8.12.PASA.4",
      bookName: "Saint Vincent Pallotti and the Catholic Apostolate",
      author: "Vincent Pallotti",
    },
    {
      accountNumber: "498",
      callNumber: "8.12.PASA.5",
      bookName: "Saint Vincent Pallotti and the Catholic Apostolate",
      author: "Vincent Pallotti",
    },
    {
      accountNumber: "499",
      callNumber: "8.12.PASA.6",
      bookName: "Saint Vincent Pallotti and the Catholic Apostolate",
      author: "Vincent Pallotti",
    },
    {
      accountNumber: "500",
      callNumber: "8.12.PASA.7",
      bookName: "Saint Vincent Pallotti and the Catholic Apostolate",
      author: "Vincent Pallotti",
    },
    {
      accountNumber: "501",
      callNumber: "8.12.PASA.8",
      bookName: "Saint Vincent Pallotti and the Catholic Apostolate",
      author: "Vincent Pallotti",
    },
    {
      accountNumber: "502",
      callNumber: "8.12.PASA.9",
      bookName: "Saint Vincent Pallotti and the Catholic Apostolate",
      author: "Vincent Pallotti",
    },
    {
      accountNumber: "503",
      callNumber: "8.12.BRUN.10",
      bookName: "Union of Catholic Apostolate The",
      author: "Dean ed.",
    },
    {
      accountNumber: "504",
      callNumber: "8.12.BRUN.11",
      bookName: "Union of Catholic Apostolate The",
      author: "Dean ed.",
    },
    {
      accountNumber: "505",
      callNumber: "8.12.BRUN.12",
      bookName: "Union of Catholic Apostolate The",
      author: "Dean ed.",
    },
    {
      accountNumber: "506",
      callNumber: "8.12.NOLA.13",
      bookName: "La Congregazione Delle Suore Dell`Apostolato Cattolico",
      author: "Battistina M.",
    },
    {
      accountNumber: "507",
      callNumber: "8.12.NOLA.14",
      bookName: "La Congregazione Delle Suore Dell`Apostolato Cattolico",
      author: "Battistina M.",
    },
    {
      accountNumber: "508",
      callNumber: "8.12.UNCO.15",
      bookName: "Community Prayers",
      author: "Union of the Catholic Apostolate",
    },
    {
      accountNumber: "509",
      callNumber: "8.12.UNGE.16",
      bookName: "General Statutes",
      author: "Unione Dell`Apostolato Cattolic",
    },
    {
      accountNumber: "510",
      callNumber: "8.12.JAEM.17",
      bookName: "Empowered by Love",
      author: "Pat",
    },
    {
      accountNumber: "511",
      callNumber: "8.12.UNGE.18",
      bookName: "General Statutes, Union of Catholic Apostolate - 2003",
      author: "Union of the Catholic Apostolate",
    },
    {
      accountNumber: "512",
      callNumber: "8.12.UNGE.19",
      bookName: "General Statutes, Union of Catholic Apostolate - 2003",
      author: "Union of the Catholic Apostolate",
    },
    {
      accountNumber: "513",
      callNumber: "8.12.UNGE.20",
      bookName: "General Statutes - 2008",
      author: "Union of the Catholic Apostolate",
    },
    {
      accountNumber: "514",
      callNumber: "8.12.NACH.21",
      bookName: "Chosen to Bear Fruits that Endure",
      author: "Pallottine Animation Centre",
    },
    {
      accountNumber: "515",
      callNumber: "8.12.FRME.22",
      bookName: "Memoria e Profezia Dell`Unione Dell`Apostolato Cattolic",
      author: "Seamus",
    },
    {
      accountNumber: "516",
      callNumber: "8.12.SOIN.23",
      bookName: "In der \\kirche angenommen",
      author: "Hubert Socha",
    },
    {
      accountNumber: "517",
      callNumber: "8.13.MAHE.1",
      bookName: "He Wanted the People to Evangelize",
      author: "Leon Martin",
    },
    {
      accountNumber: "518",
      callNumber: "8.13.MAHE.2",
      bookName: "He Wanted the People to Evangelize",
      author: "Leon Martin",
    },
    {
      accountNumber: "519",
      callNumber: "8.13.MAHE.3",
      bookName: "He Wanted the People to Evangelize",
      author: "Leon Martin",
    },
    {
      accountNumber: "520",
      callNumber: "8.13.MAHE.4",
      bookName: "He Wanted the People to Evangelize",
      author: "Leon Martin",
    },
    {
      accountNumber: "521",
      callNumber: "8.13.MAHE.5",
      bookName: "He Wanted the People to Evangelize",
      author: "Leon Martin",
    },
    {
      accountNumber: "522",
      callNumber: "8.13.MAHE.6",
      bookName: "He Wanted the People to Evangelize",
      author: "Leon Martin",
    },
    {
      accountNumber: "523",
      callNumber: "8.13.MAHE.7",
      bookName: "He Wanted the People to Evangelize",
      author: "Leon Martin",
    },
    {
      accountNumber: "524",
      callNumber: "8.13.MAHE.8",
      bookName: "He Wanted the People to Evangelize",
      author: "Leon Martin",
    },
    {
      accountNumber: "525",
      callNumber: "8.13.MAHE.9",
      bookName: "He Wanted the People to Evangelize",
      author: "Leon Martin",
    },
    {
      accountNumber: "526",
      callNumber: "8.13.DANO.10",
      bookName: "Novena in the Spirit of St. Vincent Pallotti",
      author: "Joseph",
    },
    {
      accountNumber: "527",
      callNumber: "8.13.DANO.11",
      bookName: "Novena in the Spirit of St. Vincent Pallotti",
      author: "Joseph",
    },
    {
      accountNumber: "528",
      callNumber: "8.13.SOLI.12",
      bookName: "Liturgical Celebrations of the Society of the Catholic Apo",
      author: "Society of the Catholic Apostola",
    },
    {
      accountNumber: "529",
      callNumber: "8.13.ANNO.13",
      bookName: "Novena In Honour of St. Vincent Pallotti",
      author: "Anonymous",
    },
    {
      accountNumber: "530",
      callNumber: "8.13.ANNO.14",
      bookName: "Novena In Honour of St. Vincent Pallotti",
      author: "Anonymous",
    },
    {
      accountNumber: "531",
      callNumber: "8.13.FEPA.15",
      bookName: "Pallotti Hat Geeufen...",
      author: "Alois",
    },
    {
      accountNumber: "532",
      callNumber: "8.13.EIPA.16",
      bookName: "Pallottiner Jagesqebete",
      author: "Josef",
    },
    {
      accountNumber: "533",
      callNumber: "8.13.ANNO.17",
      bookName: "Novena in Honour of St. Vincent Pallotti",
      author: "Anonymous",
    },
    {
      accountNumber: "534",
      callNumber: "8.13.UNCO.18",
      bookName: "Community Prayers",
      author: "Union of the Catholic Apostolate",
    },
    {
      accountNumber: "535",
      callNumber: "8.13.KOTN.19",
      bookName: "Thirty Day Retreat (Eucharistic)",
      author: "Augustine Kolencherry",
    },
    {
      accountNumber: "536",
      callNumber: "8.13.MIPR.20",
      bookName: "Prayers for Many Special Occasions",
      author: "Pallottine Fathers and Brothers",
    },
    {
      accountNumber: "537",
      callNumber: "8.13.MIPR.21",
      bookName: "Prayers for Many Special Occasions",
      author: "Pallottine Fathers and Brothers",
    },
    {
      accountNumber: "538",
      callNumber: "8.13.MIPR.22",
      bookName: "Prayers ofr Many Special Occasions",
      author: "Pallottine Fathers and Brothers",
    },
    {
      accountNumber: "539",
      callNumber: "8.13.DAST.23",
      bookName: "St. Vincent Pallotti",
      author: "Fr. Joseph Danko. compil.",
    },
    {
      accountNumber: "540",
      callNumber: "8.13.DAST.24",
      bookName: "St. Vincent Pallotti",
      author: "Fr. Joseph Danko. compil.",
    },
    {
      accountNumber: "541",
      callNumber: "8.13.SOSA.25",
      bookName: "San Vincenzp Pallotti, Sacerdote",
      author: "Societa dell Apostolato Cattolico",
    },
    {
      accountNumber: "542",
      callNumber: "8.13.PIPR.26",
      bookName: "Preghiere Comunitarie Pallottine",
      author: "Piazza San Vincenzo Pallotti - R",
    },
    {
      accountNumber: "543",
      callNumber: "8.13.UNCO.27",
      bookName: "Community Prayers",
      author: "Union of the Catholic Apostolate",
    },
    {
      accountNumber: "544",
      callNumber: "7.10.SOAC.1",
      bookName: "Acta Societatis Apostolatus Catholici - XIV - 1989",
      author: "Society of the Catholic Apostola",
    },
    {
      accountNumber: "545",
      callNumber: "7.10.SOAC.2",
      bookName: "Acta Societatis Apostolatus Catholici - XXI - 2002-2004",
      author: "Society of the Catholic Apostola",
    },
    {
      accountNumber: "546",
      callNumber: "8.13.PAWA.28",
      bookName: "Way of the Cross with St. Vincent Pallotti",
      author: "Pallotti Institute, Mysore",
    },
    {
      accountNumber: "547",
      callNumber: "8.13.PAWA.29",
      bookName: "Way of the Cross with St. Vincent Pallotti",
      author: "Pallotti Institute, Mysore",
    },
    {
      accountNumber: "548",
      callNumber: "8.13.GEST.30",
      bookName: "Stations of the Cross in the Spirit of St. Vincent Pallotti T",
      author: "Vensus George",
    },
    {
      accountNumber: "549",
      callNumber: "8.13.EDSA.31",
      bookName: "Saint Vincent Pallotti, Lives of light",
      author: "Editions du Signe",
    },
    {
      accountNumber: "550",
      callNumber: "8.13.EDSA.32",
      bookName: "Saint Vincent Pallotti, Lives of light",
      author: "Editions du Signe",
    },
    {
      accountNumber: "551",
      callNumber: "8.13.GEME.33",
      bookName: "Meditations on the Mysteries of the Holy  Rosary in the S",
      author: "Vensus George",
    },
    {
      accountNumber: "552",
      callNumber: "8.10.KAMA.98",
      bookName: "May Devotion in the life of St. Vincent Pallotti",
      author: "Mathew Kanjiramkalayil SAC",
    },
    {
      accountNumber: "553",
      callNumber: "4.10.KAST.87",
      bookName: "St. Vincent Pallotti - Founder of Pallottines",
      author: "Mathew  Kanjiramkalayil",
    },
    {
      accountNumber: "554",
      callNumber: "4.10.KAST.88",
      bookName: "Sant Vincent Pallotti - Pallottine Samaj Ke Samstapak [Hi",
      author: "Mathew  Kanjiramkalayil",
    },
    {
      accountNumber: "555",
      callNumber: "4.10.KAST.89",
      bookName: "Sant Vincent Pallotti - Pallottine Samaj Ke Samstapak [Hi",
      author: "Mathew  Kanjiramkalayil",
    },
    {
      accountNumber: "556",
      callNumber: "4.10.KAVI.90",
      bookName: "Vi. Vincent Pallotti - Pallottine Sabha Stapakan [Malayala",
      author: "Mathew  Kanjiramkalayil",
    },
    {
      accountNumber: "557",
      callNumber: "4.10.KAVI.91",
      bookName: "Vi. Vincent Pallotti - Pallottine Sabha Stapakan [Malayala",
      author: "Mathew  Kanjiramkalayil",
    },
    {
      accountNumber: "558",
      callNumber: "8.12.UNGE.24",
      bookName: "General Statutes - 2008",
      author: "Union of the Catholic Apostolate",
    },
    {
      accountNumber: "559",
      callNumber: "8.10.DHYO.99",
      bookName: "Youth Work and the Charism of St. Vincent Pallotti The",
      author: "James Justin Dhiraviam SAC",
    },
    {
      accountNumber: "560",
      callNumber: "8.10.DHYO.100",
      bookName: "Youth Work and the Charism of St. Vincent Pallotti The",
      author: "James Justin Dhiraviam SAC",
    },
    {
      accountNumber: "561",
      callNumber: "Not Updated",
      bookName: "Prekursor Apostolstwa Swieckich",
      author: "Czeslaw Parzyszek SAC",
    },
    {
      accountNumber: "562",
      callNumber: "Not Updated",
      bookName: "Listy Lacinskie",
      author: "Bogdana SAC",
    },
    {
      accountNumber: "563",
      callNumber: "Not Updated",
      bookName: "Charyzmat Sw. Wincentego Pallottiego",
      author: "Zjednoczenie Apostolstwa Katol",
    },
    {
      accountNumber: "564",
      callNumber: "Not Updated",
      bookName: "Sw. Wincenty Pallotti o Eucharystii",
      author: "Iwona Meger",
    },
    {
      accountNumber: "565",
      callNumber: "Not Updated",
      bookName: "Duchowosc Apostolska Wedlug Sw. Wincentego Pallottie",
      author: "Czeslaw Parzyszek SAC",
    },
    {
      accountNumber: "566",
      callNumber: "Not Updated",
      bookName: "Znad Tybru Na Podniebne Szlaki",
      author: "Franciszek Bodgan",
    },
    {
      accountNumber: "567",
      callNumber: "Not Updated",
      bookName: "Swietosc W Sluzbie Apostolstwa",
      author: "Franciszk Amoroso",
    },
    {
      accountNumber: "568",
      callNumber: "Not Updated",
      bookName: "Od Nicosci Do Pelni W Bogu",
      author: "Francesco Amoroso",
    },
    {
      accountNumber: "569",
      callNumber: "Not Updated",
      bookName: "Bog Milosc Nieskonczona",
      author: "Sw. Vincenty Pallotti",
    },
    {
      accountNumber: "570",
      callNumber: "Not Updated",
      bookName: "Statut Generalny",
      author: "Zjednoczenie Apostolstwa Katol",
    },
    {
      accountNumber: "571",
      callNumber: "Not Updated",
      bookName: "Ku Zjednoczeniu Z Bogiem",
      author: "Przemystaw Krakowczyk",
    },
    {
      accountNumber: "572",
      callNumber: "Not Updated",
      bookName: "Czcigodny Wincenty  Pallotti",
      author: "Pawel De Geslin",
    },
    {
      accountNumber: "573",
      callNumber: "Not Updated",
      bookName: "Vincenty Pallotti",
      author: "Not Updated",
    },
    {
      accountNumber: "574",
      callNumber: "Not Updated",
      bookName: "Pisma Duchowe, Czesc 1,Wybor Pism Tom IV",
      author: "Not Updated",
    },
    {
      accountNumber: "575",
      callNumber: "Not Updated",
      bookName: "Pisma Dotyczace Zjednoczenia Apostolaswa Katolickigeo",
      author: "Not Updated",
    },
    {
      accountNumber: "576",
      callNumber: "Not Updated",
      bookName: "Pisma Dotyczace Zjednoczenia Apostolaswa Katolickigeo",
      author: "Not Updated",
    },
    {
      accountNumber: "577",
      callNumber: "Not Updated",
      bookName: "Pisma Pallottiego Dotyczace Jego DuchowosciWybor Pis",
      author: "Not Updated",
    },
    {
      accountNumber: "578",
      callNumber: "Not Updated",
      bookName: "Jedno Zycie  to Za mato",
      author: "Franciszek Mickiewicz",
    },
    {
      accountNumber: "579",
      callNumber: "Not Updated",
      bookName: "Listy [1816-1833] Tom I",
      author: "Sw. Vincenty Pallotti",
    },
    {
      accountNumber: "580",
      callNumber: "Not Updated",
      bookName: "Sw. Wincenty Pallotti, Apostol I Mistyk",
      author: "Jozef Wrobel",
    },
    {
      accountNumber: "581",
      callNumber: "Not Updated",
      bookName: "Vinzez Pallotti, Impulse Aus Seiner Spiritualiat",
      author: "Not Updated",
    },
    {
      accountNumber: "582",
      callNumber: "3.11.SOFI.59",
      bookName: "Final Document of the XVIII General Assembly",
      author: "Society of the Catholic Apostola",
    },
    {
      accountNumber: "583",
      callNumber: "3.11.SOIN.60",
      bookName: "In a Constant Search for Faithfulness",
      author: "Seamus",
    },
    {
      accountNumber: "584",
      callNumber: "4.10.SAST.92",
      bookName: "St. Vincent Pallotti, An English Reader",
      author: "SAC Publications",
    },
    {
      accountNumber: "585",
      callNumber: "8.10.PRTH.104",
      bookName: "Theological Foundations and Pastoral Implications of the",
      author: "Prabodhana, Mysore",
    },
    {
      accountNumber: "586",
      callNumber: "8.10.PRTH.105",
      bookName: "Theological Foundations and Pastoral Implications of the",
      author: "Prabodhana, Mysore",
    },
    {
      accountNumber: "587",
      callNumber: "2.11.GAPA.54",
      bookName: "Pallottine Congress 2001",
      author: "Vensus A. ed.",
    },
    {
      accountNumber: "588",
      callNumber: "8.13.ANNO.34",
      bookName: "Novena In Honour of St. Vincent Pallotti",
      author: "Anonymous",
    },
    {
      accountNumber: "589",
      callNumber: "8.10.SAIL.101",
      bookName: "Il Carisma Pallottino Nell'apostolato dell'Educazione: Rea",
      author: "SAC- Segretario Generale per l'",
    },
    {
      accountNumber: "590",
      callNumber: "8.10.SAIL.102",
      bookName: "Il Carisma Pallottino Nell'apostolato dell'Educazione: Rea",
      author: "SAC- Segretario Generale per l'",
    },
    {
      accountNumber: "591",
      callNumber: "8.10.STSO.103",
      bookName: "Societa Dell Apostolato Cattolico",
      author: "Not Updated",
    },
    {
      accountNumber: "592",
      callNumber: "4.10.KONI.93",
      bookName: "Nithiathayude Apostolen",
      author: "George Kommattom",
    },
    {
      accountNumber: "593",
      callNumber: "3.11.FRGE.61",
      bookName: "General Statutes, Union of Catholic Apostolate",
      author: "Seamus",
    },
    {
      accountNumber: "594",
      callNumber: "2.10.ANLI.16",
      bookName: "Like a Mustard Seed",
      author: "Fr. James Anchukandathil",
    },
  ],
  specificCategory: [
    "Writings of Pallotti: SAC",
    "History of SAC in General",
    "Regional Histories, Descriptions, Chronicles: SAC",
    "Fundamental Law & Commentaries: SAC",
    "Fundamental Law & Commentaries: SAC",
    "Supplementary Law and Its Commentaries: SAC",
    "Decrees of Gen. Chapters & of Rector Generals: SAC",
    "Biographies of the Founder: SAC",
    "Biographies of Other Notable Persons in SAC: SAC",
    "Catalogue of the Society: SAC",
    "ACTA Societatis Apostolatus Catholici: SAC",
    "Specific aspect of Pallotti & Pallottine Life: SAC",
    "Formation in SAC",
    "Union of the Catholic Apostolate: SAC",
    "Pallottine Devotions and Prayers",
  ],
  eBooks: [
    { bookName: "Novena", fileName: "Novena.pdf" },
    {
      bookName: "Sayings of a Saint",
      fileName: "Sayings_of_a_Saint_Augustine_Kolenchery.pdf",
    },
    {
      bookName: "St. Vincent Pallotti, An Inspiration and model for everyone",
      fileName:
        "St. Vincent Pallotti, An Inspiration and model for everyone.pdf",
    },
    {
      bookName: "St. Vincent Pallotti by Fr. Augustine Kolencherry",
      fileName: "St.Vincent_Pallotti_Augustine_Kolencherry.pdf",
    },
    {
      bookName: "Way of the Cross",
      fileName: "Pallottine_Way_of_the_Cross.pdf",
    },
    {
      bookName: "Spiritual Last Will of St. Vincent Pallotti",
      fileName: "Spiritual_Last_Will_Of_St. Vincent_Pallotti.pdf",
    },
    {
      bookName: "St. Vincent Pallotti by Fr. Mathew Kanjiramkalayil",
      fileName: "St.Vincent_Pallotti_Mathew_KC.pdf",
    },
    { bookName: "In the Footsteps", fileName: "In_the_Footsteps.pdf" },
    {
      bookName: "May Devotion by Fr. Mathew K.C.",
      fileName: "May_Devotion_Mathew_KC.pdf",
    },
  ],
  reflections: [
    { refName: "Priestly Ordination of St. Vincent Pallotti", fileName: "Pallotti_Ordination.pdf", month: "May, 2021" },
    { refName: "Bl. Joseph Stanek", fileName: "JosephStanek.pdf", month: "March, 2021"  },
    { refName: "Birth of St. Vincent Pallotti", fileName: "Pallotti_Birth.pdf", month: "April, 2021"  },
    { refName: "Reflection of St. Vincent Pallotti on Epidemic", fileName: "Pallotti_Epidemic.pdf",month: "September, 2020"},
    { refName: "Reflection of St. Vincent Pallotti on Epidemic", fileName: "Pallotti_Epidemic.pdf",month: "September, 2020"  },
    { refName: "Reflection of St. Vincent Pallotti on Epidemic", fileName: "Pallotti_Epidemic.pdf",month: "September, 2020"  },
    { refName: "Reflection on St. Vincent Pallotti a Deacon", fileName: "Pallotti_Deacon.pdf", month: "September, 2020"  },
    { refName: "Reflection of St. Vincent Pallotti on Death", fileName: "Pallotti_Death.pdf", month: "September, 2020"  },
    { refName: "Reflection of St. Vincent Pallotti on Christmas", fileName: "Pallotti_Christmas.pdf", month: "December, 2020"  },
    { refName: "St. Vincent Pallotti :Life and Ministry", fileName: "Pallotti_life.pdf", month: "January, 2021"  },
    { refName: "St Vincent Pallotti and the celebration of the Octave of the Epiphany", fileName: "Pallotti_Epiphany.pdf", month: "January, 2020"  },
    { refName: "Bl. Elizabeth Sanna :Life ", fileName: "Elizebeth.pdf",month: "Febraury, 2021"  },
  ],
  quotes:[{qot:"Every person is a Christian, every Christian is an Apostle."},
  {qot:"God is the true life; God will always be with you, thus you will always have the true life."},
	{qot:"It is our task to cooperate in the spreading of the Gospel with the power of God at work in us."},
  {qot:"Where you cannot reach with your works, you will reach with humility and trust in God."},
  {qot:"Each and everyone must be imbued with a burning desire to do everything in his or her power to promote the greater glory of God."},
  {qot:"The Love of Our Lord Jesus Christ urges everyone to the work of the apostolate so that they may receive the honorable title ‘apostle’."},
  {qot:"Anyone can excel in the work of the apostolate, for God judges the value of our labour according to the merits of our heart."},
  {qot:"Every one of us has a sacred commitment: to always live in God, to speak for God, and to use all our senses, the power of our soul to advance the salvation of ourselves and our neighbour."},
  {qot:"Lord, make my life a witness to the works of mercy, both material and spiritual."},
  {qot:"Before doing anything, we should reflect on how Jesus would think, speak and act in our situation and then give it our best shot."},
  {qot:"If cheerfulness and joy are lacking, few will be attracted to follow Jesus Christ."},
  {qot:"By myself I can do nothing, with God I can do everything."},
  {qot:"Never relent in your apostolic work. If you do that, you work out of obligation rather than out of love."},
  {qot:"God will do everything when we do everything with the conviction that we can do nothing without God."},
  {qot:"Even the best efforts of the individual cannot be successful unless they are united and directed to a common goal."},
  {qot:"Lord, I should not even have the courage to invoke You, because in so many ways, I have rebelled, betrayed and crucified You."},
  {qot:"Prayer is the infallible means to obtain from God everything we desire."},
  {qot:"Can you pray for the salvation of others? Pray!  You will be an apostle."},
  {qot:"Prayer is the infallible means to obtain from God everything we desire."},
  {qot:"Lord, may it not be just lip service what I say about you. But that I truly believe it and that I am deeply convinced in my heart. "},
  {qot:"Let us pray so that we may often enter into the spirit of God’s interests."},
  {qot:"Whoever, with increasing fervor, unites the contemplative life with the active, will realize the fullness of Christ."},
  {qot:"Anyone who prays must do so with faith and trust."},
  {qot:"May the most precious will of God be our food, our drink, our rest and our heaven on earth."},
  {qot:"Let us pray that the work of the Blessed Trinity be realized in us and be increasingly more valuable to us."},
  {qot:"The best method of private prayer is that which the spirit of the person finds easiest and most fruitful."},
  {qot:"Prayer is easy and possible for anyone because divine mercy does not exclude anyone, but urges all to pray."},
  {qot:"If you do not pray or pray properly, we build a dam before the goodness of God who wants to inundate us."},
  {qot:"During the day humble and fervent short prayers must be recited."},
  {qot:"	My God, I intend to be ready to listen to your voice, your inspiration, your all in every infinite moment."},
  {qot:"Jesus assures us: the more we hunger and thirst for holiness, the more we shall be filled."},
	{qot:"God will do everything, when we will do everything with the conviction that we can do nothing with out God. "},
  ],
  articles: [
    {
      fileName:
        "Agape in St. Paul, the Apostle and Charity in St. Vincent Pallotti, Biblical Theological Indications for the Pallottine Spirituality.pdf",
      articleName:
        "Agape in St. Paul, the Apostle and Charity in St. Vincent Pallotti, Biblical Theological Indications for the Pallottine Spirituality",
    },

    {
      fileName: "CHARISM OF ST. VINCENT PALLOTTI.pdf",
      articleName: "Charism of st.Vincent Pallotti",
    },

    {
      fileName:
        "Holiness of St. Vincent Pallotti, A Model for Contemporary Christians.pdf",
      articleName:
        "Holiness of St. Vincent Pallotti, A Model for Contemporary Christians",
    },

    {
      fileName: "Holiness, A Call Towards Fullness.pdf",
      articleName: "Holiness, A Call Towards Fullness",
    },

    {
      fileName: "Holiness, Living for the Glory of God.pdf",
      articleName: "Holiness, Living for the Glory of God",
    },

    {
      fileName:
        "Pallotti’s Understanding of Universal Apostolate and Its Relevance Today.pdf",
      articleName:
        "Pallotti’s Understanding of Universal Apostolate and Its Relevance Today",
    },

    {
      fileName:
        "Relevance of Vincent Pallotti’s Concept of Mission for our Times.pdf",
      articleName:
        "Relevance of Vincent Pallotti’s Concept of Mission for our Times",
    },

    {
      fileName: "Reviving Faith and Rekindling Charity in a Changing World.pdf",
      articleName: "Reviving Faith and Rekindling Charity in a Changing World",
    },

    {
      fileName: "The Alchemy of St. Vincent Pallotti.pdf",
      articleName: "The Alchemy of St. Vincent Pallotti",
    },

    {
      fileName: "The Holy Spirit of God and Christian Holiness.pdf",
      articleName: "The Holy Spirit of God and Christian Holiness",
    },

    {
      fileName: "The Role of Mary in the Holiness of St. Vincent Pallotti.pdf",
      articleName: "The Role of Mary in the Holiness of St. Vincent Pallotti",
    },

    {
      fileName:
        "The Union of the Catholic Apostolate in the Pluralistic Context of India.pdf",
      articleName:
        "The Union of the Catholic Apostolate in the Pluralistic Context of India",
    },

    {
      fileName: "The Yearning of a Soul.pdf",
      articleName: "The Yearning of a Soul",
    },

    {
      fileName: "Vincent Pallotti and Universal Call to Holiness.pdf",
      articleName: "Vincent Pallotti and Universal Call to Holiness",
    },
  ],
  videos: [
    {
      title: "St. Vincent Pallotti: Priestly Ordination (English)",
      link: " https://youtu.be/Z8osaUQeAO8",
    }, 
    {
      title: "Birth and Childhood of St. Vincent Pallotti - English",
      link:
        "https://youtu.be/74TMhj2cJO0",
        
    },
    {
      title: "St. Vincent Pallotti: Life - English",
      link:
        "https://youtu.be/wQNBz_k7-U0",
    },
    {
      title: "St. Joseph Stanek: Life - English",
      link:
        "https://youtu.be/4s3z1oVc8O8",
        
    },
    {
      title: "St. Elizabeth Sanna: Life - English",
      link:
        "https://youtu.be/aTss0eTMhqU",
        
    },
    {
      title: "Pallotti And Epidemic - English",
      link:
        "https://www.youtube.com/watch?v=wgehpYd_Uqs&ab_channel=PallottiInstituteMysuru",
    },
    {
      title: "St.Vincent Pallotti's Reflection on Diaconate - English",
      link:
        "https://youtu.be/w25jYHw0yv4",
    },
    {
      title: "St. Vincent Pallotti's Reflection on Death - English",
      link:
        "https://youtu.be/CqzLJ9rdUm8",
    },
    {
      title: "St. Vincent Pallotti's Reflection on Christmas - English",
      link:
        "https://youtu.be/82c9fVcvAyU",
    },
   
    {
      title: "Prekshitha Varyan  - Pallotti Hymn Malayalam",
      link:
        "https://youtu.be/taXfuyk1Vok",
        
    },
  ],
};
