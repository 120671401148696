import { CollectionsOutlined } from "@material-ui/icons";
import React from "react";
import { InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";
import clone from "rfdc";
const SearchRecord = (props) => {
  const filterRecords = (value) => {
    const oldRecords = clone()(props.records);
    let filter =
      value === ""
        ? props.records
        : oldRecords.filter((record) =>
            props.filterKey
              ? record[props.filterKey].toLowerCase().indexOf(value) > -1
              : record.toLowerCase().indexOf(value) > -1
          );
    props.setFilteredRecords(filter);
  };
  return (
    <>
      <InputGroup>
        <Input
          placeholder={"Search " + props.placeHolder}
          onChange={(e) => filterRecords(e.target.value)}
        />
        <InputGroupAddon addonType="append"></InputGroupAddon>
      </InputGroup>
      <br />
    </>
  );
};
export default SearchRecord;
