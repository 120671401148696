/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const Modals = (props) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <Modal isOpen={props.isOpen}>
      <ModalHeader toggle={props.closeModal}>{props.title}</ModalHeader>
      <ModalBody>{props.children} </ModalBody>
      <ModalFooter>
        <Button color="info" size="sm" onClick={props.closeModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Modals;
