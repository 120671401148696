import React from "react";
const TableHeader = (props) => {
  return (
    <thead>
      <tr>
        <td className="indexNumber">#</td>
        {props.tableHeader.map((item) => (
          <td>{item}</td>
        ))}
      </tr>
    </thead>
  );
};
export default TableHeader;
