import React from "react";
import FollowAt from "react-social-media-follow";

const SocialIcon = () => {
  const links = [
    "https://www.facebook.com/groups/2587772191533491",
    "https://www.youtube.com/channel/UC8O-L-xUV1pNdSoLn27tDmA",
    "https://www.instagram.com/PallottiInstituteMysuru",
  ];

  return <FollowAt links={links} hoverMove={false} color />;
};

export default SocialIcon;
