import React, { useState } from "react";
import Header from "../Header/Header";
import Navbar from "../NavBar/Navbar";
import Footer from "../Footer/Footer";
import MainContent from "../MainContent/MainContent";
import classes from "../NavBar/navbar.module.css";
import SideDrawer from "../NavBar/SideDrawer/SideDrawer";

const Layout = (props) => {
  const [show, setSideBar] = useState(false);

  const showSideBar = () => {
    setSideBar(true);
  };
  const closeSideBar = () => {
    setSideBar(false);
  };
  return (
    <>
      <Navbar showSideBar={showSideBar} />
      <SideDrawer show={show} closeSideBar={closeSideBar} />
       <Header closeSideBar={closeSideBar} /> 
      <MainContent closeSideBar={closeSideBar}>{props.children}</MainContent>
      <Footer />
    </>
  );
};
export default Layout;
