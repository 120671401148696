import React from "react";

import classes from "./header.module.css";
import { UncontrolledCarousel } from "reactstrap";
import DailyQuote from "./DailyQuote";
const ImageSlider = (props) => {
  const items = [
    {
      src: require("../../assets/slider3.jpg"),
    },
    {
      src: require("../../assets/slider1.jpg"),
    },
    {
      src: require("../../assets/slider2.jpg"),
    },
    {
      src: require("../../assets/slider4.jpg"),
    },
  ];

  return (
    <div>
    <div className={classes.slider}>
      <UncontrolledCarousel items={items} indicators={true} controls={false} />
    </div>
    <p></p>
    <DailyQuote/>
    </div>
  );
};
export default ImageSlider;
