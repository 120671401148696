import React from "react";
import { Row, Col } from "reactstrap";
import institute from "../assets/institute.JPG";
import institute2 from "../assets/institute2.JPG";
import logo from "../assets/logooo.png";
import rector from "../assets/rector.jpg";

const AboutUs = () => {
  return (
    <div className="main-content aboutUs">
      <h4>Pallotti Institute Mysuru</h4>
      <Row>
        <Col md={8}>
          <p>
            Pallotti Institute at Prabodhana, Mysuru in
            India was established in the year 2011. This is one of the 6 Pallotti Institutes in the world, which caters the needs for 6
            different languages. The main and the centre, of course, is located in Rome
            in our Generalate . The other
            Institutes are in Germany (for the German language), in Poland (for
            the Polish language), in Brazil (for the Portuguese language), in
            Cameroon (for French language - and for the whole of Africa). Pallotti Institute in
            Mysuru, India is meant for all the English speaking
            countries. The Pallotti Institutes aims
        to be centres of research and learning on Pallotti, which makes Pallotti and
        his charism known to people. It tries to create network of communication &
        sharing of materials on Pallotti. It tries to form members in the spirit &
        charism of Pallotti and find ways to inculcate Pallottine charism. Institute also
        promotes theological research (the work of major seminaries &
        institutes), specially for the students of Prabodhana. 
          </p>
        </Col>
        <Col md={4}>
          <img src={institute} alt="institute image1" />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <img src={institute2} alt="institute image2" />
        </Col>
        <Col md={8}>
          <p>
          Institute also tries to collaborate 
        and exchange experiences in the realization of the programmes in various Pallotti Institutes.
        Each Institute has its director and its council, which spearheads the activities and initatives in various Institutes.
            Pallotti Institute Mysuru leads the Pallotti Week celebration in the Prabodhana, Pallottine theologate in India. 
            During the week it organizesPallottine courses for the 4 batches of students
            in the theologate. The courses are: Pallottine
            Spirituality (for 1st years), Pallottine Charism & Apostolate (for
            2nd years), History of the Society (for 3rd years) and the
            Constitutions (for 4th years). The week is also flavoured by different cultural programmes and competitions
            which brings out the Pallottine and Spirituality. Institute has also initiated an Epiphany Triduum celebration in 
            month of January, to reproduce and remember the special interest our founder had in the Epiphany ocatve. isntitute also
            brings out monthly Pallottine reflections in 7 different languages. Institute also runs a daily Pallotti quote camapangin titled
            "PALLOTTI SPEAKS", which reachs 1000+ people everyday. 
          </p>
        </Col>
      </Row>
      <hr size="8" width="100%" color="red"/>
      <h4>Coat of Arms</h4>
      <Row>
        <Col md={8}>
          <p>
            The Coat of Arms of the Institute depicts the purpose and nature of the Institute.
            The 2 hands in the Coat of Arms represents the fact that Pallotti Institute is a gift from God to the Church.
            The 2 Trumpets represents the universal call for all the members of the Church (Clergy, Religious and Laity 
            represented by the 3 coloured human figures) to engage in Apostolate of the Church, since the harvest is plentiful
            but the labourers are few. 
          </p>
          <p>
            The Coat of Arms of the Institute was selected from the number of entries given by the Pallottine brothers and
            sisters of Prabodhana and Queen Mary Convent respectively. 
          </p>
        </Col>
        <Col md={4}>
          <center><img src={logo}  style={{width: 300,height: 300}} alt="Logo"/></center>
        </Col>
      </Row>
      <hr size="8" width="100%" color="red"/>
      <div>
        <h4>Rector General's Message</h4>
        <Row>
        <Col md={3} sm={12}>
            <img src={rector} alt="rector" />
            <p><h5>Fr Jacob Nampudakam SAC</h5></p>
          </Col>
          <Col md={8} sm={12}>
            <p>
              <ul>
                <li>to be centres of research and learning on Pallotti</li>
                <li>to make Pallotti and his charism known to people</li>
                <li>
                  to provide a network of communication & sharing of materials
                  on Pallotti
                </li>
                <li>
                  to form members and lay people in the spirit & charism of
                  Pallotti
                </li>
                <li>to find ways to difuse Pallotti’s charism more widely</li>
                <li>
                  to promote theological research (the work of major seminaries
                  & institutes)
                </li>
                <li>
                  to exchange experiences in the realization of the programmes
                  in various Pallotti Institutes
                </li>
              </ul>
             
            </p>
          </Col>
        </Row>
        </div>
    </div>
  );
};
export default AboutUs;
