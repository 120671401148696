import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import GeneralCategory from "../components/Resources/GeneralCategory";
import jsonData from "../json/booksJson";
import SpecificCategory from "../components/Resources/SpecificCategory";
import Ebooks from "../components/Resources/Ebooks";
import Reflections from "../components/Resources/Reflections";
import Videos from "../components/Resources/Videos";

import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
const Resources = (props) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const disclaimer = (
    <blockquote>
      <PriorityHighIcon />
      You can download these files. We are grateful to the people who own the
      copyright and others who supported us in this venture. To print and
      publish these materials, one is required to obtain permission from the
      original publishers.
    </blockquote>
  );

  return (
    <div>
      <Nav pills>
        <NavItem>
          <NavLink
            className={classnames([
              {
                active: activeTab === "1",
              },
              "default-tabs",
            ])}
            onClick={() => {
              toggle("1");
            }}
          >
            Pallottine Reflections
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames([
              {
                active: activeTab === "2",
              },
              "default-tabs",
            ])}
            onClick={() => {
              toggle("2");
            }}
          >
            Articles
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames([
              {
                active: activeTab === "3",
              },
              "default-tabs",
            ])}
            onClick={() => {
              toggle("3");
            }}
          >
            Videos
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames([
              {
                active: activeTab === "4",
              },
              "default-tabs",
            ])}
            onClick={() => {
              toggle("4");
            }}
          >
            E-books
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              {disclaimer}
              <Reflections
                data={jsonData.reflections}
                type="reflections"
                tableHeader={["Reflection Name", "Action"]}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col sm="12">
              {disclaimer}

              <Ebooks
                data={jsonData.articles}
                type="articles"
                tableHeader={["Article Name", "Action"]}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row>
            <Col sm="12">
              {disclaimer}

              <Videos
                data={jsonData.videos}
                type="articles"
                tableHeader={["Title", "Action"]}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="4">
          <Row>
            <Col sm="12">
              <Ebooks
                data={jsonData.eBooks}
                type="eBooks"
                tableHeader={["Book Name", "Action"]}
              />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default Resources;
