import React, { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Table } from "reactstrap";

import CustomPagination from "../Pagination/Pagination";
import SearchRecord from "./Search";
import TableHeader from "./TableHeader";

const Videos = (props) => {
  const [jsonData, updateJson] = useState([]);

  const paginatedRecords = (newRecordSet) => {
    updateJson(newRecordSet);
  };
  const filteredRecords = (newRecordSet) => {
    updateJson(newRecordSet);
  };

  return (
    <>
      <SearchRecord
        placeHolder="Video Title"
        setFilteredRecords={filteredRecords}
        filterKey={"title"}
        records={[...props.data]}
      />
      <Table bordered hover responsive>
        <TableHeader tableHeader={props.tableHeader} />
        <tbody>
          {jsonData &&
            jsonData.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.title}</td>

                  <td>
                    {item.link && (
                      <a href={item.link} target={"_blank"}>
                        View
                      </a>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <CustomPagination
        records={[...props.data]}
        paginatedRecords={paginatedRecords}
      />
    </>
  );
};

export default Videos;
