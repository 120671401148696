import React from "react";
import PhoneIcon from "@material-ui/icons/Phone";
import HomeIcon from "@material-ui/icons/Home";
import EmailIcon from "@material-ui/icons/Email";
const ContactUs = () => {
  return (
    <div className="address">
      <div>
        <h3>PRABODHANA</h3>
        <p>
          Pallottine Centre For Theological and Religious Formation <br />
          H.D. Kote Road, Kalawadi Gate, Udhbur P.O., <br />
          Mysore - 570008 <br />
          Karnataka, India
        </p>
        <p>
          <PhoneIcon /> (0821) 2597973, 2597775
        </p>
        <p>
          <EmailIcon /> pallottiinstitutemysuru@gmail.com
        </p>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3899.212416457796!2d76.60526031400116!3d12.233891891342834!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baf65a544e5c34f%3A0x8206522a078fa7e7!2sPrabodhana%20Pallottine%20Theologate!5e0!3m2!1sen!2sin!4v1596379389672!5m2!1sen!2sin"
        width="800"
        height="350"
        frameborder="0"
        style={{ border: 0 }}
        aria-hidden="false"
        tabindex="0"
      ></iframe>
    </div>
  );
};
export default ContactUs;
