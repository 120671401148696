import React, { useState } from "react";
import { Table } from "reactstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import CustomPagination from "../Pagination/Pagination";
import SearchRecord from "./Search";
import TableHeader from "./TableHeader";
import { FiberSmartRecord } from "@material-ui/icons";

const Reflections = (props) => {
  const [jsonData, updateJson] = useState([]);

  const paginatedRecords = (newRecordSet) => {
    updateJson(newRecordSet);
  };
  const filteredRecords = (newRecordSet) => {
    updateJson(newRecordSet);
  };

  return (
    <>
      <SearchRecord
        placeHolder="Reflections"
        setFilteredRecords={filteredRecords}
        filterKey={"refName"}
        records={[...props.data]}
      />
      <Table bordered hover responsive>
        <TableHeader tableHeader={props.tableHeader} />
        <tbody>
          {jsonData &&
            jsonData.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    {item.refName }
                  </td>

                  <td>
                    <a
                      href={ "/reflections/" + item.fileName
                      }
                      target="_blank"
                    >
                      View
                    </a>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <CustomPagination
        records={[...props.data]}
        paginatedRecords={paginatedRecords}
      />
    </>
  );
};

export default Reflections;
