import React from "react";
import { HashRouter, Route } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import HomePage from "./views/HomePage";
import AboutSaint from "./views/AboutSaint";
import AboutUs from "./views/AboutUs";
import Resources from "./views/Resources";
import Gallery from "./views/Gallery";
import ContactUs from "./views/ContactUs";

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Layout>
          <Route path="/aboutUs" exact component={AboutUs} />
          <Route path="/aboutSaint" exact component={AboutSaint} />
          <Route path="/resources" exact component={Resources} />
          <Route path="/gallery" exact component={Gallery} />
          <Route path="/contact" exact component={ContactUs} />
          <Route path="/home" exact component={HomePage} />
          <Route path="/" exact component={HomePage} />
        </Layout>
      </HashRouter>
    </div>
  );
}

export default App;
