import React from "react";
import classes from "./header.module.css";
import ImageSlider from "./ImageSlider";

const Header = (props) => {
  return (
   <div className={classes.header} onClick={props.closeSideBar}>
       {/*  <p>
       <span>Enligtened 
            </span>to<br />
        <span>Enlighten</span>
        & <span>Radiate</span>
        <br /> <span>Pallotti.</span>
      </p>*/}
    <ImageSlider />
    </div>
   
  );
};

export default Header;
