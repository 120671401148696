import React from "react";
import { NavLink } from "react-router-dom";
import classes from "./navbar.module.css";
const NavItems = () => {
  return (
    <ul>
      <li>
        <NavLink to="/home" activeClassName={classes.activelink}>
          Home
        </NavLink>
      </li>
      <li>
        <NavLink to="/aboutUs" activeClassName={classes.activelink}>
          About Us
        </NavLink>
      </li>
      <li>
        <NavLink to="/aboutSaint" activeClassName={classes.activelink}>
          St.Vincent Pallotti
        </NavLink>
      </li>
      <li>
        <NavLink to="/resources" activeClassName={classes.activelink}>
          Resources
        </NavLink>
      </li>
      <li>
        <NavLink to="/gallery" activeClassName={classes.activelink}>
          Gallery
        </NavLink>
      </li>
      <li>
        <NavLink to="/contact" activeClassName={classes.activelink}>
          Contact Us
        </NavLink>
      </li>
    </ul>
  );
};
export default NavItems;
