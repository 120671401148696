import React from "react";
import saintImg from "../assets/saint.jpg";
const AboutSaint = () => {
  return (
    <div className="main-content aboutSaint">
      <h4>St.Vincent Pallotti</h4>
      <blockquote>
        "Vincent Pallotti aroused the consciousness of the laity. He let loose a
        new energy in the Church. He gave to lay people an awareness of their
        own potential to do good. He enriched the Christian community with a
        variety of vocations, not merely of passive acceptance or a faith of
        ease, but rather a call to the active and even staunch profession of
        faith." - Pope Paul VI
      </blockquote>
      <img src={saintImg} alt="saint" />
      <p>
        ‘Vincent Pallotti aroused the consciousness of the laity, he let loose a
        new energy in the Church. He gave to lay people an awareness of their
        own potential to do good. He enriched the Christian community with a
        variety of vocations, not merely of passive acceptance or a faith of
        ease, but rather a call to the active and even staunch profession of
        faith.’ - Pope Paul VI From his boyhood years, he sought personal
        perfection with an extraordinary fervor, but did not isolate himself
        from the world. He gave generously of his help and presence wherever
        needed, especially to young people. He was ordained a priest on May 16,
        1818. For Pallotti, priestly life was a full-time task and a humble
        service. He was best known as a preacher and a confessor. During parish
        missions he touched many who had minimal contact with the Church. Many
        who listened to him began to live a more coherent Christian life, and it
        was not long before he became known as the “Apostle of Rome.” Despite
        his numerous activities, Pallotti never lost sight of the untold needs
        of people. He could be found pleading for support in the villas of the
        rich. He frequently came with aid to the poor. He gave comfort to the
        sick and dying. He visited military barracks & prisons, and without
        hesitation was a companion of the condemned, even to the place of
        execution. For abandoned children, he opened residential homes and
        evening trade schools for adults. There were so many needs to be met,
        which was such an enormous task, that he invited the help of priests,
        religious and lay people to participate in these endeavours. For him, it
        was imperative to unite every available source to help in the effort to
        revive faith and promote the love of God and neighbour. It was with this
        intent, that in 1835 Vincent Pallotti founded the Union of x Catholic
        Apostolate. Pallotti envisioned no distinction between the laity and the
        clergy, because he was convinced that all the faithful, each in his or
        her own role in life and each according to personal ability, could be an
        apostle and could cooperate to further the growth and deepening of faith
        and love. To take care of the abandoned children he founded also the
        Sisters of the Catholic Apostolate in 1838. Vincent Pallotti died on
        January 22, 1850. Pope Pius XII proclaimed him “Blessed” and in 1963,
        Pope John XXIII formally declared him a Saint.
      </p>
      <hr size="8" width="100%" color="red"/>
      <h4>Spirituality of Vincent Pallotti</h4>
      <p>
        The dynamic principle on which the multi-faceted apostolic activities of
        St.Vincent Pallotti were founded was his personal faith experience. God
        gave him, as a gift of the Spirit, a profound experience of His infinite
        love and mercy. According to Vincent Pallotti, the most profound
        motivation of the divine action is infinite love. For this reason men
        and women, created in the image and likeness of God, reach the full
        understanding of the meaning of life when they continually exercise the
        love of God and love of neighbour (cf.1 Jn 4:16). This experience
        permits men and women to understand Christ as the Apostle of the Eternal
        Father. All that Jesus achieved during his life on earth derives from
        his love of the Father and from his saving love of all people (cf. OOCC
        III, 175 ff.). To live the love of God our Father and love of our
        neighbour in imitation of Christ is the secret of the apostolic efficacy
        of every Christian. For Vincent Pallotti, therefore, love is the
        essential motive for anyone who wishes to collaborate in the apostolate.
        The following of Christ and participation in His mission to save all
        people are inseparable. In as much as all are called to be disciples all
        are obliged to be apostles (cf. OOCCIII, 142). Mary, Queen of Apostles,
        is, "after Jesus Christ, the most perfect model of true Catholic zeal
        and perfect charity, because she was so committed to the greater glory
        of God and the good of souls… That she surpassed the Apostles in merit"
        (OOCC I, 7). The title of ‘Mary, Queen of Apostles,’ is for St. Vincent,
        a symbol, a programme, a more efficacious example and a more perfect
        model for the apostolate of the faithful.
      </p>
    </div>
  );
};
export default AboutSaint;
