import React from "react";
import { Link } from 'react-router-dom';
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { Row, Col } from "reactstrap";
import { LatestReflection } from "./LatestReflection";
import institute from "../assets/institute.JPG";
import svp from "../assets/svp.jpg";
import ban2 from "../assets/ban2.jpg";
import wrm from "../assets/WRM.jpg";
import res from "../assets/res1.png";
import gal from "../assets/gal.png";
import au from "../assets/aboutus.png";
import Reflections from "../components/Resources/Reflections";
import jsonData from "../json/booksJson";
const HomePage = () => {
  return (
      <div className="main-content homePage">
       
        <Row style={{ background:' #127681 ',display:'flex'}}>  
          
          <Col  sm={12}>
            <center><LatestReflection/></center>
          </Col>
         
        </Row>
        <div style={{width:'40px', height:'20px'}}></div>
        <h4>Pallotti Institute Mysuru</h4>
        <Row>
        <Col md={4}>
          <img src={institute} alt="institute image1" style={{height:'270px',border: '1px solid #127681'}} />
        </Col>
          <Col sm={8}>
            <p>
            Pallotti Institute at Prabodhana, Mysuru in India was established in the year 2011. 
            This is one of the 6 Pallotti Institutes in the world, which caters the needsfor 6 
            different languages. The main and the centre, of course, is located in Rome in our 
            Generalate . The other Institutes are in Germany (for the German language), in Poland 
            (for the Polish language), in Brazil (for the Portuguese language), in Cameroon 
            (for French language - and for the whole of Africa). Pallotti Institute in Mysuru, 
            India is meant for all the English speaking countries. 
            The Pallotti Institutes aims to be centres of research and learning on Pallotti, 
            which makes Pallotti and his charism known to people. 
            It tries to create network of communication & sharing of materials on Pallotti. 
            It tries to form members in the spirit & charism of Pallotti and find ways to inculcate 
            Pallottine charism. Institute also promotes theological research (the work of major seminaries & institutes), specially for the students of Prabodhana.
              <NavLink to="/aboutUs"> Read More</NavLink>
            </p>
          </Col>
        </Row>
        <Row>
        <Col  sm={3}>
            <center>
            <NavLink to="/aboutUs"> 
            <img src={au} alt="video" style={{width:'200px', height:'200px',borderRadius: 200 / 2,}}/>
          </NavLink>
              <h4 style={{marginTop: 10,fontSize: 20,color: '#0250a3', fontWeight: 'bold',textAlign:'center'}}>About Us</h4></center>
          </Col> <Col  sm={3}>
            <center><NavLink to="/aboutSaint"> <img src={svp} alt="video" style={{width:'200px', height:'200px',borderRadius: 200 / 2,}}/></NavLink>
            <h4 style={{marginTop: 10,fontSize: 20,color: '#0250a3', fontWeight: 'bold'}}>St.Vincent Pallotti</h4></center>
          </Col> <Col  sm={3}>
            <center><NavLink to="/resources"> <img src={res} alt="video" style={{width:'200px', height:'200px',borderRadius: 200 / 2,border: '3px solid #127681'}}/></NavLink>
            <h4 style={{marginTop: 10,fontSize: 20,color: '#0250a3', fontWeight: 'bold'}}>Resources</h4></center>
          </Col> <Col  sm={3}>
            <center><NavLink to="/gallery"> <img src={gal} alt="video" style={{width:'200px', height:'200px',borderRadius: 200 / 2,border: '3px solid #127681'}}/></NavLink>
            <h4 style={{marginTop: 10,fontSize: 20,color: '#0250a3', fontWeight: 'bold'}}>Gallery</h4></center>
          </Col>
        </Row>
            <a href="https://www.sac.info/">
              <img src={ban2} alt="We are a Mission" style={{ height:'100px',border: '1px solid #127681'}}/>
            </a>
         
      </div>
  );
};

export default HomePage;
