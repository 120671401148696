import React from "react";
import jsonData from "../json/booksJson";
import pdf from "../assets/pdf.png";
import vid from "../assets/video.png";
import { Height } from "@material-ui/icons";

export const LatestReflection = () => {
  return (<div style={{width:'100%',height: '50px'}}>
      { jsonData.reflections.slice(0,1).map((data, key) => {
          return (
           
             <table>
               <tr style={{display: 'flex'}}>
                    <td style={{padding: '15px'}}><h5 style={{color: 'white'}}>Pallottine Monthly Reflection </h5>
                    </td><td style={{padding: '15px',paddingLeft:'5px'}}>
              <h5 style={{color: 'white'}}>{data.month}</h5></td>
               <td className='blink' style={{padding: '15px',paddingLeft:'5px',color: 'white'}}> <h4 style={{color: 'red'}}>{data.refName}</h4></td>
               <td style={{paddingLeft: '15px', alignItems: 'center',Justify:'center'}}>
                 <a title="PDF DOWNLOAD" 
                      href={ "/reflections/" + data.fileName
                      }
                      target="_blank"
                    >
                       <img src={pdf} alt="pdf" style={{width:'35px', height:'35px'}}/>
                        
                    </a></td>
               <td style={{alignItems: 'center',Justify:'center'}}>{ jsonData.videos.slice(0,1).map((data, key) => {
                      return (
                        <a title="WATCH IN YOUTUBE" href={data.link} target={"_blank"}>
                        {
                           <img src={vid} alt="video" style={{width:'35px', height:'35px'}}/>
                        }
                        </a>
                      ); 
                    })}</td>
               </tr></table>
                 
            
          ); 
        })}
        </div>
  );
};