import React, { useState } from "react";
import ImageGallery from "react-image-gallery";

import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Row,
  Col,
} from "reactstrap";
import galleryJson from "../../json/galleryJson";
import Modals from "../Modals/Modals";

const Albums = (props) => {
  const [isShowModal, setShowModal] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [title, setTitle] = useState("");

  const prepareGallery = (folderName, totalImages, title) => {
    let images = [];
    for (let i = 1; i <= totalImages; i++) {
      images.push({
        original: require("../../assets/gallery/" +
          folderName +
          "/" +
          "img" +
          i +
          ".jpg"),
        thumbnail: require("../../assets/gallery/" +
          folderName +
          "/" +
          "img" +
          i +
          ".jpg"),
      });
    }

    images.length > 0 && setGalleryImages(images);
    setShowModal(true);
    setTitle(title);
  };

  return (
    <>
      <Row>
        {galleryJson.map((event) => (
          <Col sm={12} md={3}>
            <Card>
              <CardImg
                top
                height="150px"
                src={require("../../assets/gallery/" +
                  event.folderName +
                  "/" +
                  event.thumbNail)}
                alt="Card image cap"
              />
              <CardBody>
                <CardTitle>{event.title}</CardTitle>
                <Button
                  size="sm"
                  color="warning"
                  onClick={() =>
                    prepareGallery(
                      event.folderName,
                      event.totalImages,
                      event.title
                    )
                  }
                >
                  Open Album
                </Button>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
      <Modals
        isOpen={isShowModal}
        closeModal={() => setShowModal(false)}
        title={title}
      >
        <ImageGallery items={galleryImages} />
      </Modals>
    </>
  );
};

export default Albums;
