import React, { useState, useEffect } from "react";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import classes from "./pagination.module.css";
import { useEventCallback } from "@material-ui/core";
const CustomPagination = (props) => {
  const defaultRecordToShow =
    props.records.length < 25 ? props.records.length : 25;

  const totalRecordsLength = props.records.length;

  const [range, setRange] = useState({ from: 0, to: defaultRecordToShow - 1 });

  const showPagination = props.records.length > defaultRecordToShow;

  const getRecord = useEventCallback((value) => {
    switch (value) {
      case "first":
        setNewRange(0, defaultRecordToShow - 1);

        break;
      case "before":
        if (range.to > defaultRecordToShow) {
          range.to > defaultRecordToShow
            ? setNewRange(range.from - defaultRecordToShow, range.from - 1)
            : setNewRange(0, defaultRecordToShow - 1);
        }
        break;
      case "next":
        if (range.to < totalRecordsLength - 1) {
          range.to + defaultRecordToShow >= totalRecordsLength - 1
            ? setNewRange(range.to + 1, totalRecordsLength - 1)
            : setNewRange(range.to + 1, range.to + defaultRecordToShow);
        }
        break;
      case "last":
        if (range.to < totalRecordsLength - 1) {
          let from =
            totalRecordsLength -
            1 -
            ((totalRecordsLength - 1) % defaultRecordToShow);
          setNewRange(from, totalRecordsLength - 1);
        }
        break;
      default:
        break;
    }
  });
  useEffect(() => {
    getRecord("first");
  }, [getRecord]);

  const setNewRange = (from, to) => {
    let data = props.records;
    let newRecords = [];

    for (let i = from; i <= to; i++) {
      newRecords.push(data[i]);
    }
    // console.log(newRecords);
    setRange({ ...range, from: from, to: to });
    // console.log(from + "-" + to + " of " + (totalRecordsLength - 1));
    props.paginatedRecords(newRecords);
  };

  return (
    <>
      {showPagination && (
        <div className={classes.pagination}>
          <p>
            {"Showing " +
              (range.from + 1) +
              "-" +
              (range.to + 1) +
              " of " +
              totalRecordsLength}
          </p>
          <div>
            <SkipPreviousIcon onClick={getRecord.bind(this, "first")} />
          </div>
          <div>
            <NavigateBeforeIcon onClick={getRecord.bind(this, "before")} />
          </div>
          <div>
            <NavigateNextIcon onClick={getRecord.bind(this, "next")} />
          </div>
          <div>
            <SkipNextIcon onClick={getRecord.bind(this, "last")} />
          </div>
        </div>
      )}
    </>
  );
};

export default CustomPagination;
