import React from "react";
import jsonData from "../../json/booksJson";
import { Height } from "@material-ui/icons";

export const DailyQuote = () => {
    const current = new Date();
    const day = current.getDate();
  return (
    <>
      { 
      jsonData.quotes.slice(day-1,day).map((data, key) => {
          return (  <center><h5 style= {{color:'white'}}>"{data.qot}" St. Vincent Pallotti</h5></center>
          ); 
        })}
        
    </>
  );
};
export default DailyQuote;