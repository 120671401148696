import React, { useContext } from "react";
// import Backdrop from "../../../components/UI/Backdrop/Backdrop";
import classes from "./SideDrawer.module.css";
import NavItems from "../NavItems";

// import { BackDropContext } from "../../../hoc/Context";

const SideDrawer = (props) => {
  let sideDrawClasses = [classes.SideDrawer, classes.Close];

  if (props.show) {
    sideDrawClasses = [classes.SideDrawer, classes.Open];
  }

  return (
    <div className={sideDrawClasses.join(" ")} onClick={props.closeSideBar}>
      <nav>
        <div
          style={{
            padding: "10px ",
            position: "absolute",
            top: "0",
            width: "100%",
            background: "#127681",
          }}
        >
          <h6 style={{ margin: "0", color: "#fff" }}>PALLOTTI INSTITUTE</h6>
        </div>
        <NavItems />
      </nav>
    </div>
  );
};

export default SideDrawer;
